import {
  watchRegisterIncoming,
  watchUploadFile,
  watchUploadPhoto,
  watchUploadPhotos,
  watchPhotosAreCompleted,
  watchAddFileInsurance
} from './registerVehicle';

export {
  watchRegisterIncoming,
  watchUploadFile,
  watchUploadPhoto,
  watchUploadPhotos,
  watchPhotosAreCompleted,
  watchAddFileInsurance
};
