import { takeEvery, put, call, cancelled } from 'redux-saga/effects';
import {
  VEHICLE_OUTGOING_REASON_REQUEST,
  vehicleOutgoingReasonSuccess,
  vehicleOutgoingReasonFail
} from '../actions';
import instance from 'services/request';
import { responseOK } from 'utils';
import { getOutgoingReasons } from '../../services';

export function* workVehicleOutgoingReasonRequest() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOutgoingReasons, {
      cancelToken: source.token
    });
    if (responseOK(response)) {
      yield put(vehicleOutgoingReasonSuccess(response.data.data));
    } else {
      yield put(vehicleOutgoingReasonFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(vehicleOutgoingReasonFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchVehicleOutgoingReasonRequest() {
  yield takeEvery(VEHICLE_OUTGOING_REASON_REQUEST, workVehicleOutgoingReasonRequest);
}
