import React, { useRef, useState, useEffect } from 'react';
import { PageHeader, Icon, Row, Modal } from 'antd';
import SButton from 'components/common/SButton';
import WizardFormExternal from 'components/WizardFormExternal';
import { useHistory } from 'react-router-dom';
import FooterWizardActions from 'components/common/FooterWizardActions';
import VehicleInfo from 'components/awardings/detail/delivery/VehicleInfo';
import UbicationInfo from 'components/awardings/detail/delivery/UbicationInfo';
import DiverseInfo from 'components/awardings/detail/delivery/DiverseInfo';
import FilesInfo from 'components/awardings/detail/delivery/FilesInfo';
import DeliveryComment from 'components/awardings/detail/delivery/DeliveryComment';
import {
  getDeliveryRequest,
  postDeliveryCommentRequest,
  getDeliveryClean
} from 'redux/actions/buyersActions';
import { postBdeliveryRequest } from 'redux/actions/caseActions';
import { caseFileszipRequest, caseFilesClean } from 'redux/actions/filesActions';
import { connect } from 'react-redux';
import { getDataOnSplitURL } from 'utils';
import { caseTypes } from 'models/enums';
import Loader from 'components/common/Loader';
import { sinesterIcon } from 'utils/icons';
import DropDownButton from 'components/common/DropDownButton';
import { useTranslation } from 'react-i18next';

//TODO: Clean the store when unmounting

function BuyerDeliveryInfo(props) {
  const ref = useRef();
  const {
    getDeliveryRequest: getDeliveryReq,
    location,
    deliveryDetail,
    files,
    orderDownload,
    postDeliveryCommentRequest: postCommentReq,
    actions,
    caseFileszipRequest: caseFileszipReq,
    caseFilesClean: cleanFiles,
    postBdeliveryRequest: postBdeliveryReq
  } = props;
  const history = useHistory();
  const { push } = history;
  const [showModal, setShowModal] = useState(false);
  //Get Case Id from URL
  const { pathname: URL } = location;
  const caseId = getDataOnSplitURL(URL, 4);
  const { t } = useTranslation();

  useEffect(() => {
    cleanFiles();
  }, [cleanFiles]);
  // Make the request when entering the page
  useEffect(() => {
    getDeliveryReq({ caseId: caseId });
  }, [getDeliveryReq, caseId]);

  const handleActions = actionId => {
    if (actionId === 'cancel') {
      history.goBack();
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalOk = () => {
    const { current } = ref;
    const { validateFields } = current;
    validateFields(async (err, values) => {
      const data = {
        ...values,
        caseId: caseId
      };
      if (!err) {
        postCommentReq({ data: data, onSuccess: handleModalCancel, caseId: caseId });
      }
    });
  };

  const handleDownloadZipFiles = () => {
    caseFileszipReq({ caseId: caseId });
  };

  const handleGenerateDeliveryOrder = itemKey => {
    if (itemKey === 'EMAIL') {
      postBdeliveryReq({ data: { caseId: caseId, GenerateAction: 1 } });
    } else if (itemKey === 'DOWNLOAD') {
      postBdeliveryReq({ data: { caseId: caseId, GenerateAction: 2 } });
    }
  };

  //TODO: Extract this into a component
  const renderActions = () => {
    return (
      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <SButton
          style={{ margin: 5 }}
          key="detail"
          label={t('inventory.vehicle.exit.detail.deliverycomment')}
          onClick={() => setShowModal(true)}
        />
        {deliveryDetail.data && deliveryDetail.data.showDownloadCarDocuments && (
          <SButton
            style={{ margin: 5 }}
            key="detail"
            label={t('buyerdeliveryinfo.downloadfiles')}
            loading={files && files.isLoading}
            onClick={handleDownloadZipFiles}
          />
        )}

        <DropDownButton
          icon="download"
          shape="round"
          style={{ margin: 5 }}
          type="primary"
          loading={orderDownload && orderDownload.isLoading}
          key="detail"
          label={t('inventory.vehicle.detail.generate')}
          menu={[
            { id: 'EMAIL', label: t('inventory.vehicle.detail.generate.opt1') },
            { id: 'DOWNLOAD', label: t('inventory.vehicle.detail.generate.opt2') }
          ]}
          onItemClick={clickedElementData => {
            handleGenerateDeliveryOrder(clickedElementData.key);
          }}
        />

        <Modal
          destroyOnClose
          onCancel={handleModalCancel}
          visible={showModal}
          title={t('inventory.vehicle.exit.detail.deliverycomment')}
          footer={[
            <SButton key="back" onClick={handleModalCancel}>
              Cancelar
            </SButton>,
            <SButton
              label={t('system.acept')}
              key="submit"
              type="primary"
              onClick={handleModalOk}
              loading={
                actions && actions.isPostingDeliveryComment
                  ? actions.isPostingDeliveryComment
                  : false
              }
            />
          ]}
        >
          <DeliveryComment ref={ref} {...commonProps} />
        </Modal>
      </Row>
    );
  };

  const commonProps = {
    data: deliveryDetail.data,
    renderActions: renderActions,
    caseId: caseId
  };

  const vehicleForms = [
    {
      title: 'Vehículo',
      content: <VehicleInfo {...commonProps} />,
      icon: <Icon type="car" />
    },
    {
      title: 'Ubicación',
      content: <UbicationInfo {...commonProps} />,
      icon: <Icon type="environment" />
    },
    {
      title: 'Documentos',
      content: <FilesInfo {...commonProps} />,
      icon: <Icon type="folder-open" />
    }
  ];

  const diverseForms = [
    {
      title: 'Diverso',
      content: <DiverseInfo {...commonProps} />,
      icon: <Icon component={sinesterIcon} />
    },
    {
      title: 'Ubicación',
      content: <UbicationInfo {...commonProps} />,
      icon: <Icon type="environment" />
    }
  ];

  const getForms = data => {
    let forms;
    if (data && data.caseTypeId) {
      if (data.caseTypeId === caseTypes.VEHICULOS) {
        forms = vehicleForms;
      } else if (data.caseTypeId === caseTypes.DIVERSOS) {
        forms = diverseForms;
      }
    }

    return forms;
  };

  return deliveryDetail.isLoading ? (
    <Loader isPageLoader />
  ) : (
    <>
      <PageHeader title={t('buyerawardingdetail.consultdeliveryinfo')} />

      <WizardFormExternal
        isClickEnable
        cleanInitialForms={false}
        withRef={false}
        formsExternal={getForms(deliveryDetail.data)}
      />
      <FooterWizardActions onCancel handleAction={handleActions} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    deliveryDetail: state.buyers.deliveryDetail,
    actions: state.buyers.actions,
    files: state.files.zipDownload,
    orderDownload: state.case.orderDownload
  };
}

export default connect(mapStateToProps, {
  getDeliveryRequest,
  postDeliveryCommentRequest,
  caseFileszipRequest,
  getDeliveryClean,
  postBdeliveryRequest,
  caseFilesClean
})(BuyerDeliveryInfo);
