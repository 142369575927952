// Actions
import {
  VEHICLES_INCOMING_REQUEST,
  VEHICLES_INCOMING_SUCCESS,
  VEHICLES_INCOMING_FAIL,
  VEHICLES_INCOMING_CLEAN
} from '../actions/index';

// Default state
const vehiclesIncomingReducerDefaultState = {};

export default (state = vehiclesIncomingReducerDefaultState, action) => {
  switch (action.type) {
    case VEHICLES_INCOMING_REQUEST:
      return { ...state, isLoading: true };
    case VEHICLES_INCOMING_SUCCESS:
      return { ...state, isLoading: false, data: action?.payload };
    case VEHICLES_INCOMING_CLEAN:
      return { isLoading: false, data: [] };
    case VEHICLES_INCOMING_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
