import { put, takeEvery, call, cancelled } from 'redux-saga/effects';
import { picsInventoryCheckOutAdd } from 'redux/actions/picsActions';
import {
  UPLOAD_CHECKOUT_PHOTO_REQUEST,
  uploadOutgoingPhotoFail,
  uploadOutgoingPhotoSuccess
} from '../actions';
import { uploadPhoto } from 'modules/RegisterOutgoingVehicle/services';
import instance from 'services/request';
import { responseOK } from 'utils';

export function* workerAddPicture(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const response = yield call(uploadPhoto, { data: payload, cancelToken: source.token });
    if (responseOK(response)) {
      yield put(uploadOutgoingPhotoSuccess(response.data.data));
      yield put(
        picsInventoryCheckOutAdd({
          fileId: response.data.data,
          content: payload.content,
          name: payload.name
        })
      );
    } else {
      yield put(uploadOutgoingPhotoFail());
    }
  } catch (_error) {
    yield put(uploadOutgoingPhotoFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchUploadPhoto() {
  yield takeEvery(UPLOAD_CHECKOUT_PHOTO_REQUEST, workerAddPicture);
}
