import { takeEvery, put, call, cancelled } from 'redux-saga/effects'; //agregar call
import { INVENTORY_REQUEST, inventorySuccess, inventoryFail } from '../actions';
import instance from 'services/request';
import { getInventory } from 'modules/Inventory/services';

export function* workInventoryRequest(action) {
  // agregar action
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const response = yield call(getInventory, {
      params: payload.params,
      cancelToken: source.token
    });
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(inventorySuccess(response.data.data));
      // console.log('params', payload.params);
      // console.log('response', response.data);
      // console.log('It succeeded');
    } else {
      yield put(inventoryFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(inventoryFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
      yield put(inventoryFail());
    }
  }
}

export function* watchInventoryRequest() {
  yield takeEvery(INVENTORY_REQUEST, workInventoryRequest);
}
