import {
  VEHICLE_EXITS_REQUEST,
  VEHICLE_EXITS_FAIL,
  VEHICLE_EXITS_SUCCESS,
  VEHICLE_EXITS_CLEAN
} from '../actions/index';

const vehicleExitsDefaultState = {};

export default (state = vehicleExitsDefaultState, action) => {
  switch (action.type) {
    case VEHICLE_EXITS_REQUEST:
      return { ...state, isLoading: true };
    case VEHICLE_EXITS_SUCCESS:
      return { ...state, isLoading: false, data: action?.payload };
    case VEHICLE_EXITS_FAIL:
      return { ...state, isLoading: false };
    case VEHICLE_EXITS_CLEAN:
      return { ...state, isLoading: false, data: [] };
    default:
      return state;
  }
};
