import { takeEvery, put, call } from 'redux-saga/effects';
import { TRANSFER_DETAIL_REQUEST, transferDetailSuccess, transferDetailFail } from '../actions';
import { getTransfer } from 'modules/Transfer/services';

function* workTransferDetailRequest(action) {
  try {
    const {
      payload: { transferId }
    } = action;
    const response = yield call(getTransfer, { transferId });
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(
        transferDetailSuccess({
          transferStatusId: response?.data?.destination?.statusId,
          ...response.data
        })
      );
    } else {
      yield put(transferDetailFail());
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(transferDetailFail());
  }
}

export function* watchTransferDetailRequest() {
  yield takeEvery(TRANSFER_DETAIL_REQUEST, workTransferDetailRequest);
}
