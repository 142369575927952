import request from 'services/request';
const serviceURL = 'Transfers/api/v1/transfers';

export async function getTransfer({ transferId }) {
  return request(`${serviceURL}/${transferId}`, {
    method: 'GET'
  });
}

export async function getTransferActions({ params }) {
  return request(`${serviceURL}/${params?.transferId}`, {
    method: 'GET'
  });
}
