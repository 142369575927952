import { put, call, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import { fetchByPagination } from '../../services';
import instance from 'services/request';
import { responseOK } from 'utils';

export function* workNotifications(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const {
      payload: { page, pageLength }
    } = action;
    const response = yield call(fetchByPagination, {
      page,
      pageLength,
      cancelToken: source.token
    });

    if (responseOK(response)) {
      yield put(actions.allNotificationsSuccess(response.data.data));
    } else {
      yield put(actions.allNotificationsFail());
    }
  } catch (_error) {
    yield put(actions.allNotificationsFail());
  }
}

export function* watchAllNotificationsRequest() {
  yield takeEvery(actions.ALL_NOTIFICATIONS_REQUEST, workNotifications);
}
