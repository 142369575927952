import {
  UPDATE_ADD_FILE_INSURANCE,
  UPDATE_REMOVE_LOCAL_FILE_INSURANCE,
  UPDATE_CLEAN_FILES,
  UPDATE_REMOVE_DB_FILE_INSURANCE,
  UPDATE_FILE_REQUEST,
  UPDATE_FILE_FAIL,
  UPDATE_ADD_FILE_FAIL,
  UPDATE_ADD_FILE_SUCCES
} from '../actions/index';

function updateManagmentFiles(state = {}, action) {
  const { type } = action;
  switch (type) {
    case UPDATE_FILE_REQUEST:
      return {
        ...state,
        isPosting: true
      };
    case UPDATE_FILE_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_ADD_FILE_INSURANCE:
      return {
        ...state,
        files: [...state.files, action.payload.file],
        isPosting: false
      };
    case UPDATE_REMOVE_LOCAL_FILE_INSURANCE:
      return {
        ...state,
        files: [...state.files.filter(ph => ph.id !== action.payload?.id)]
      };
    case UPDATE_REMOVE_DB_FILE_INSURANCE:
      return {
        ...state,
        files: [...state.files.filter(ph => ph.fileId !== action.payload?.fileId)]
      };
    case UPDATE_ADD_FILE_SUCCES:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_ADD_FILE_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_CLEAN_FILES:
      return {
        ...state,
        files: []
      };
    default:
      return state;
  }
}

export default updateManagmentFiles;
