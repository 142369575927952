import request from 'services/request';
const serviceURL = 'Inventories/Api/v1/Inventories/Graph';
export async function getInventory({ params, cancelToken }) {
  let { suppliers, branches, markets } = params;
  let auxSuppliers = '';
  let auxBranches = '';
  let auxMarkets = '';
  if (suppliers) {
    if (suppliers.length > 1) {
      suppliers.map(p => {
        if (p) auxSuppliers += `suppliers=${p}&`;
        else console.info('No data found');
      });
    } else {
      auxSuppliers = `suppliers=${suppliers}&`;
    }
  }

  if (markets) {
    if (markets.length > 1) {
      markets.map(p => {
        if (p) auxMarkets += `markets=${p}&`;
        else console.info('No data found');
      });
    } else {
      auxMarkets = `markets=${markets}&`;
    }
  }

  if (branches) {
    if (branches.length > 1) {
      branches.map(p => {
        if (p) auxBranches += `branches=${p}&`;
        else console.info('No data found');
      });
    } else {
      auxBranches = `branches=${branches}&`;
    }
  }
  return request(`${serviceURL}/?${auxSuppliers}${auxBranches}${auxMarkets}`, {
    method: 'GET',
    cancelToken
  });
}
