export const UPDATE_ENTRY_REQUEST = 'UPDATE_ENTRY_REQUEST';
export const UPDATE_ENTRY_SUCCESS = 'UPDATE_ENTRY_SUCCESS';
export const UPDATE_ENTRY_FAIL = 'UPDATE_ENTRY_FAIL';

export const UPDATE_FILE_REQUEST = 'UPDATE_FILE_REQUEST';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAIL = 'UPDATE_FILE_FAIL';

export const UPDATE_PHOTO_REQUEST = 'UPDATE_PHOTO_REQUEST';
export const UPDATE_PHOTO_SUCCESS = 'UPDATE_PHOTO_SUCCESS';
export const UPDATE_PHOTO_FAIL = 'UPDATE_PHOTO_FAIL';
export const UPDATE_PHOTOS_ARE_COMPLETED = 'UPDATE_PHOTOS_ARE_COMPLETED';
export const UPDATE_PHOTO_DELETE = 'UPDATE_PHOTO_DELETE';
export const UPDATE_FAVORITE_PIC = 'UPDATE_FAVORITE_PIC';

export const UPDATE_FILE_INSURANCE_REQUEST = 'UPDATE_FILE_INSURANCE_REQUEST';
export const UPDATE_ADD_FILE_INSURANCE = 'UPDATE_ADD_FILE_INSURANCE';
export const UPDATE_REMOVE_LOCAL_FILE_INSURANCE = 'UPDATE_REMOVE_LOCAL_FILE_INSURANCE';
export const UPDATE_CLEAN_FILES = 'UPDATE_CLEAN_FILES';
export const UPDATE_ADD_FILES_INSURANCE_DATABASE = 'UPDATE_ADD_FILES_INSURANCE_DATABASE';
export const UPDATE_REMOVE_FILE_INSURANCE = 'UPDATE_REMOVE_FILE_INSURANCE';
export const UPDATE_REMOVE_DB_FILE_INSURANCE = 'UPDATE_REMOVE_DB_FILE_INSURANCE';
export const UPDATE_ADD_FILE_SUCCES = 'UPDATE_ADD_FILE_SUCCES';
export const UPDATE_ADD_FILE_FAIL = 'UPDATE_ADD_FILE_FAIL';

export const UPDATE_ENABLED_TRANSFER = 'UPDATE_ENABLED_TRANSFER';
export const FAVORITE_PIC_UPDATE = 'FAVORIT_PIC_UPDATE';
export const CLEAN_FAVORITE_PIC = 'CLEAN_FAVORITE_PIC';

export function cleanFavoritePic() {
  return {
    type: CLEAN_FAVORITE_PIC
  };
}
export function favoritePicUpdate(payload) {
  return {
    type: FAVORITE_PIC_UPDATE,
    payload
  };
}

export function updateEnabledTransfer(payload) {
  return {
    type: UPDATE_ENABLED_TRANSFER,
    payload
  };
}

export function updateAddSucces() {
  return {
    type: UPDATE_ADD_FILE_SUCCES
  };
}
export function updateAddFail() {
  return {
    type: UPDATE_ADD_FILE_FAIL
  };
}
export function updateFavoritePic(payload) {
  return {
    type: UPDATE_FAVORITE_PIC,
    payload
  };
}
export function updateRemoveDbFile(payload) {
  return {
    type: UPDATE_REMOVE_DB_FILE_INSURANCE,
    payload
  };
}
export function updateAddDatabase(payload) {
  return {
    type: UPDATE_ADD_FILES_INSURANCE_DATABASE,
    payload
  };
}
export function updateRequestFileInsurance(payload) {
  return {
    type: UPDATE_FILE_INSURANCE_REQUEST,
    payload
  };
}

export function updateAddFileInsurance(payload) {
  return {
    type: UPDATE_ADD_FILE_INSURANCE,
    payload
  };
}

export function updatePhotoDelete(payload) {
  return {
    type: UPDATE_PHOTO_DELETE,
    payload
  };
}

export function updateRemoveFileInsurance(payload) {
  return {
    type: UPDATE_REMOVE_FILE_INSURANCE,
    payload
  };
}

export function updateRemoveLocalFileInsurance(payload) {
  return {
    type: UPDATE_REMOVE_LOCAL_FILE_INSURANCE,
    payload
  };
}
export function updateCleanFiles() {
  return {
    type: UPDATE_CLEAN_FILES
  };
}
export function updatePhotosAreCompleted(payload) {
  return {
    type: UPDATE_PHOTOS_ARE_COMPLETED,
    payload
  };
}

export const updateEntryRequest = payload => ({
  type: UPDATE_ENTRY_REQUEST,
  payload
});

export const updateEntrySuccess = payload => ({
  type: UPDATE_ENTRY_SUCCESS,
  payload
});

export const updateEntryFail = () => ({
  type: UPDATE_ENTRY_FAIL
});

export function updateFileRequest(payload) {
  return {
    type: UPDATE_FILE_REQUEST,
    payload
  };
}

export function updateFileSuccess(payload) {
  return {
    type: UPDATE_FILE_SUCCESS,
    payload
  };
}

export function updateFileFail(payload) {
  return {
    type: UPDATE_FILE_FAIL,
    payload
  };
}

export function updatePhotoRequest(payload) {
  return {
    type: UPDATE_PHOTO_REQUEST,
    payload
  };
}

export function updatePhotoSuccess(payload) {
  return {
    type: UPDATE_PHOTO_SUCCESS,
    payload
  };
}

export function updatePhotoFail(payload) {
  return {
    type: UPDATE_PHOTO_FAIL,
    payload
  };
}
