// Actions
import {
  VEHICLE_ENTRY_DETAILS_REQUEST,
  VEHICLE_ENTRY_DETAILS_SUCCESS,
  VEHICLE_ENTRY_DETAILS_FAIL,
  VEHICLE_INVENTORY_FILE_REQUEST,
  VEHICLE_INVENTORY_FILE_SUCCESS,
  VEHICLE_INVENTORY_FILE_FAIL,
  VEHICLE_ENTRY_DETAILS_CLEAN,
  DELIVERY_ORDER_REQUEST,
  DELIVERY_ORDER_SUCCESS,
  DELIVERY_ORDER_FAIL
} from '../actions/index';

// Default state
const vehicleEntryDetailsReducerDefaultState = {};

export default (state = vehicleEntryDetailsReducerDefaultState, action) => {
  switch (action.type) {
    case VEHICLE_ENTRY_DETAILS_REQUEST:
      return { ...state, isLoading: true };
    case VEHICLE_ENTRY_DETAILS_SUCCESS:
      return { ...state, isLoading: false, data: action?.payload };
    case VEHICLE_ENTRY_DETAILS_FAIL:
      return { ...state, data: {}, isLoading: false };
    case VEHICLE_INVENTORY_FILE_REQUEST:
      return {
        ...state,
        inventoryFile: {
          isLoading: true
        }
      };
    case VEHICLE_INVENTORY_FILE_SUCCESS:
      return {
        ...state,
        inventoryFile: {
          isLoading: false,
          data: action?.payload
        }
      };
    case VEHICLE_INVENTORY_FILE_FAIL:
      return {
        ...state,
        inventoryFile: {
          isLoading: false,
          data: []
        }
      };
    case VEHICLE_ENTRY_DETAILS_CLEAN:
      return { isLoading: false, data: {} };
    case DELIVERY_ORDER_REQUEST:
      return { ...state, isLoadingOrderDelivery: true };
    case DELIVERY_ORDER_SUCCESS:
      return { ...state, isLoadingOrderDelivery: false };
    case DELIVERY_ORDER_FAIL:
      return { ...state, isLoadingOrderDelivery: false };

    default:
      return state;
  }
};
