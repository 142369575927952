import React from 'react';
import { DatePicker, Button, Input, Icon } from 'antd';
import { Filter } from 'utils/icons';
import moment from 'moment';

const { RangePicker } = DatePicker;

export const getColumSearchProps = (
  dataIndex,
  clean,
  filtersColums,
  filterTable = [],
  filters = {}
) => {
  const { startDate, endDate } = filters;
  let prop = {};
  function disabledDate(current) {
    if (startDate && endDate) {
      const weekStart = moment(startDate).subtract(1, 'd');
      const weekEnd = moment(endDate).add(1, 'd');

      return !moment(current).isBetween(weekStart, weekEnd);
    } else return false;
  }

  function defaultValueDate() {
    if (startDate && filterTable.length === 0) {
      return [moment(startDate), moment(endDate)];
    } else if (filterTable && filterTable.length === 1) {
      return filterTable[0];
    } else {
      return [];
    }
  }

  if (filterTable || endDate) {
    prop = { value: defaultValueDate() };
  }
  const initialValue = value => {
    prop = { value: value };
  };

  return {
    // eslint-disable-next-line react/display-name
    filterDropdown: function ({ setSelectedKeys, confirm, clearFilters }) {
      return (
        <div className style={{ padding: 8 }}>
          <RangePicker
            jest-id="Range-Picker"
            allowClear={false}
            format="DD/MM/YYYY"
            placeholder={['Fecha Inicio', 'Fecha Final']}
            onChange={value => {
              initialValue(value);
              setSelectedKeys([value]);
            }}
            separator="-"
            disabledDate={disabledDate}
            defaultValue={defaultValueDate()}
            {...prop}
          />
          <div style={{ paddingTop: 8, textAlign: 'right' }}>
            <Button
              jest-id="Button-date"
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button
              jest-id="Button-date"
              onClick={() => {
                clean(clearFilters);
              }}
              size="small"
              style={{ width: 100 }}
            >
              Restablecer
            </Button>
          </div>
        </div>
      );
    },

    filterIcon: !filtersColums[dataIndex] ? (
      <Filter />
    ) : (
      <Icon type="loading" style={{ fontSize: '15px' }} />
    )
  };
};

export const sortDate = (title, filtersColums) => {
  return {
    // eslint-disable-next-line react/display-name
    title: ({ sortOrder }) => {
      return (
        <span style={{ margin: 'auto', display: 'flex', alignItems: 'center' }}>
          {title}

          {!filtersColums.order ? (
            <div
              style={{
                display: 'flex',
                marginLeft: '15px',
                flexDirection: 'column'
              }}
            >
              <Icon
                type="up"
                style={{
                  fontSize: '11px',
                  color: !sortOrder ? '#68368B' : '',
                  fontWeight: 'bold'
                }}
              />
              <Icon
                type="down"
                style={{
                  fontSize: '11px',
                  color: sortOrder === 'desc' ? '#68368B' : '',
                  fontWeight: 'bold'
                }}
              />
            </div>
          ) : (
            <Icon type="loading" style={{ marginLeft: '15px', fontSize: '15px' }} />
          )}
        </span>
      );
    },

    sorter: (a, b) => {
      return a + b;
    },
    sortDirections: ['desc']
  };
};

export const getSearch = (dataIndex, columnName, filtersColums) => {
  return {
    // eslint-disable-next-line react/display-name
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className="search-dropdown" style={{ padding: 8 }}>
        <Input
          jest-id="Input-Search"
          placeholder={`Burcar ${columnName}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value.trim()] : [])}
          onPressEnter={confirm}
          style={{ width: 200, marginBottom: 8, display: 'block' }}
        />
        <Button
          jest-id="Buton-Search"
          type="primary"
          onClick={() => {
            confirm();
          }}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          jest-id="Buton-Search"
          onClick={() => {
            clearFilters();
          }}
          size="small"
          style={{ width: 101 }}
        >
          Restablecer
        </Button>
      </div>
    ),

    // eslint-disable-next-line react/display-name
    filterIcon: filtered => (
      <Icon
        type={!filtersColums[dataIndex] ? 'search' : 'loading'}
        style={{ fontSize: '15px', color: filtered ? '#68368B' : undefined }}
      />
    )
  };
};

export const catalogFilter = (catalog, filter, prop) =>
  filter && filter.length
    ? catalog
      .filter(m => filter.includes(m.id))
      .map(r => ({ text: <span title={r[prop]}>{r[prop]}</span>, value: r.id }))
    : catalog.map(r => ({ text: <span title={r[prop]}>{r[prop]}</span>, value: r.id }));

export const buildUrl = params => {
  let newParams = '';
  Object.keys(params).forEach(x => {
    if (params[x] !== undefined && params[x] !== null) {
      if (typeof params[x] === 'object' && params[x].length > 0) {
        params[x].forEach(element => {
          newParams += `${x}=${element}&`;
        });
      } else if (typeof params[x] !== 'object' && params[x]) {
        newParams += `${x}=${params[x]}&`;
      }
    }
  });
  return newParams;
};

export const newParams = (filters, filtered) => {
  let newFilter = {
    ...filters
  };

  if (filtered?.insuranceCarrier) {
    newFilter.InsCarrierId = filtered?.insuranceCarrier;
  }
  if (filtered?.reason) {
    newFilter.ReasonOutId = filtered?.reason;
  }
  if (filtered?.model) {
    newFilter.model = filtered?.model?.[0];
  }
  if (filtered?.type) {
    newFilter.type = filtered?.type?.[0];
  }
  if (filtered?.brand) {
    newFilter.brand = filtered?.brand?.[0];
  }
  if (filtered?.vehicleSerial) {
    newFilter.vehicleSerial = filtered?.vehicleSerial?.[0];
  }
  if (filtered?.color) {
    newFilter.color = filtered?.color?.[0];
  }
  return newFilter;
};

export const hasChanged = (selected, newFilter, prop) =>
  prop
    ? selected[prop] &&
    newFilter[prop]?.every(i => selected[prop]?.includes(i)) &&
    selected[prop]?.every(i => newFilter[prop]?.includes(i))
    : Object.keys(newFilter)
      .filter(x => selected[x] !== newFilter[x])
      .map(k => ({ [k]: true }));

export const loadingOrderfun = (order, newOrder = 'asc') => {
  return order === newOrder;
};

export const dateHasChanged = (selected, nextFilter, Start, End) => {
  if (selected[Start] === nextFilter[Start] && selected[End] === nextFilter[End]) {
    return false;
  } else return true;
};
