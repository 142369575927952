export const SET_INVENTORY_REPORT_RESULTS = 'SET_ENTRIES_REPORT_RESULTS';
export const CLEAN_INVENTORY_RESULTS = 'CLEAN_ENTRIES_RESULTS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_HAS_HEADER_FILTERS = 'SET_HAS_HEADER_FILTERS';
export const RESET_DATA = 'RESET_DATA';

export function setInventoryReportResults(payload) {
  return {
    type: SET_INVENTORY_REPORT_RESULTS,
    payload
  };
}

export function cleanInventoryResults() {
  return {
    type: CLEAN_INVENTORY_RESULTS
  };
}

export function setInventoryFilters(payload) {
  return {
    type: SET_FILTERS,
    payload
  };
}

export const setHasHeaderFilters = isEnabled => ({
  type: SET_HAS_HEADER_FILTERS,
  isEnabled
});

export const resetData = () => ({
  type: RESET_DATA
});
