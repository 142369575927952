import request from 'services/request';
const serviceURL = 'Cases/Api/v1/Cases/TransferCase';
const serviceTransferURL = 'Transfers/Api/v1/Transfers';

export async function updateCase({ data }) {
  return request(serviceURL, {
    method: 'PUT',
    data,
    transformResponse: [
      function(d) {
        let current = JSON.parse(d);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}

export async function updateTransfers({ data }) {
  return request(serviceTransferURL, {
    method: 'PUT',
    data
  });
}
