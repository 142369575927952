export const VEHICLE_ENTRIES_REQUEST = 'VEHICLE_ENTRIES_REQUEST';
export const VEHICLE_ENTRIES_SUCCESS = 'VEHICLE_ENTRIES_SUCCESS';
export const VEHICLE_ENTRIES_FAIL = 'VEHICLE_ENTRIES_FAIL';
export const VEHICLE_ENTRIES_CLEAN = 'VEHICLE_ENTRIES_CLEAN';

export const vehicleEntriesRequest = payload => ({
  type: VEHICLE_ENTRIES_REQUEST,
  payload
});

export const vehicleEntriesSuccess = payload => ({
  type: VEHICLE_ENTRIES_SUCCESS,
  payload
});

export const vehicleEntriesFail = () => ({
  type: VEHICLE_ENTRIES_FAIL
});

export const vehicleEntriesClean = () => ({
  type: VEHICLE_ENTRIES_CLEAN
});
