import request from 'services/request';
const serviceURL = 'Inventories/Api/v1/Inventories/ListIncomingCases';

export const prepareURL = params => {
  let { providerIds, branchIds, marketsIds } = params;

  let auxSuppliers = '';
  let auxBranches = '';
  let auxMarkets = '';

  if (providerIds && providerIds.length > 0) {
    if (providerIds instanceof Array && providerIds.length > 1) {
      providerIds.map(p => (auxSuppliers += `providerIds=${p}&`));
    } else {
      auxSuppliers = `providerIds=${providerIds}&`;
    }
  }

  if (marketsIds && marketsIds.length > 0) {
    if (marketsIds instanceof Array && marketsIds.length > 1) {
      marketsIds.map(p => (auxMarkets += `marketsIds=${p}&`));
    } else {
      auxMarkets = `marketsIds=${marketsIds}&`;
    }
  }

  if (branchIds && branchIds.length > 0) {
    if (branchIds instanceof Array && branchIds.length > 1) {
      branchIds.map(p => (auxBranches += `branchIds=${p}&`));
    } else {
      auxBranches = `branchIds=${branchIds}&`;
    }
  }

  const cleanParams = () => {
    if (auxSuppliers === '' && auxBranches === '' && auxMarkets === '') {
      return '';
    } else {
      return `&${auxSuppliers}${auxBranches}${auxMarkets}`;
    }
  };
  return cleanParams();
};

export async function getVehiclesIncoming({ params, cancelToken }) {
  let { page, ...rest } = params;

  let clean = prepareURL(params);

  return request(`${serviceURL}?page=${page}${clean}`, {
    method: 'GET',
    params: rest,
    cancelToken
  });
}
