/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { setDrawerIsVisible } from 'redux/actions/layoutActions';
import { claimValue } from '../../utils/helperActions';
import { connect } from 'react-redux';

function Dashboard(props) {
  // eslint-disable-next-line no-shadow
  const {
    setDrawerIsVisible,
    claimsRole,
    claimsUser } = props;
  setDrawerIsVisible(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const tokenPic = (localStorage.getItem('tokenPic'));
  const dataB64 = window.btoa(`${user.roleId}:${user.roleId}:${user.userId}`);
  // eslint-disable-next-line no-undef
  const url = `${process.env.REACT_APP_DASHBOARD_URL}?api_key=${process.env.REACT_APP_API_KEY}&OTT=${tokenPic}&Data=${dataB64}`;


   let  enabledDashboard = claimValue(claimsRole, claimsUser, 'enabledDashboard', false);
  


  return (
    <>
      {enabledDashboard ?
        <div style={{ height: '100%', marginLeft: '-120px', marginRight: '-120px' }}>
          <iframe id="dashboard" src={url} width="80%" height="100%" className='dashboardiframe'>
            No es posible visualizar el contenido
          </iframe>
        </div> :
        <div style={{ height: '100%', marginLeft: '-120px', marginRight: '-120px' }}>
          "Dashboard"
        </div>
      }
    </>
  );
}
function mapStateToProps(state) {
  return {
    drawerIsVis: state.layout.drawerIsVisible,
    claimsRole: state?.user?.info?.claimsRole,
    claimsUser: state?.user?.info?.claimsUser,
  };
}
export default connect(mapStateToProps, {
  setDrawerIsVisible
})(Dashboard);