import {
  SET_INVENTORY_REPORT_RESULTS,
  CLEAN_INVENTORY_RESULTS,
  SET_FILTERS,
  SET_HAS_HEADER_FILTERS,
  RESET_DATA
} from '../actions/';

// Default state
const searchTransfersReducerDefaultState = {};

export default (state = searchTransfersReducerDefaultState, action) => {
  switch (action.type) {
    case SET_INVENTORY_REPORT_RESULTS:
      return { ...state, data: action?.payload };
    case CLEAN_INVENTORY_RESULTS:
      return { ...state, data: {} };
    case SET_FILTERS:
      return { ...state, filters: action?.payload };
    case SET_HAS_HEADER_FILTERS:
      return { ...state, hasHeaderFilters: action?.isEnabled };
    case RESET_DATA:
      return { ...state, data: [] };
    default:
      return state;
  }
};
