import { takeEvery, put, call, cancelled } from 'redux-saga/effects';
import {
  CASE_TRANSFER_DETAILS_REQUEST,
  caseTransferDetailsSuccess,
  caseTransferDetailsDetailsFail
} from '../actions';
import instance from 'services/request';
import { responseOK } from 'utils';
import { getCaseTransferDetail } from 'modules/TransferDetails/services/index';

export function* workCaseTransferDetailRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const response = yield call(getCaseTransferDetail, {
      params: payload.params,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      let buyerName;
      let buyerId;
      const buyer = response?.data?.data?.wreck?.buyerName;
      const id = response?.data?.data?.wreck?.buyerId;
      if (buyer) {
        buyerName = buyer;
      } else {
        buyerName = 'Otro';
      }

      if (id) {
        buyerId = id;
      } else {
        buyerId = 'other';
      }

      yield put(
        caseTransferDetailsSuccess({
          ...response.data.data,
          wreck: { ...response.data.data.wreck, buyerName, buyerId }
        })
      );
    } else {
      yield put(caseTransferDetailsDetailsFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(caseTransferDetailsDetailsFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchCaseTransferDetailRequest() {
  yield takeEvery(CASE_TRANSFER_DETAILS_REQUEST, workCaseTransferDetailRequest);
}
