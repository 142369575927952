import { watchVehicleOutgoingReasonRequest } from './getOutgoingReason';
import { watchRegisterOutgoingRequest } from './registerOutgoingVehicle';
import { watchAddFile } from './addFiles';
import { watchVehicleOutgoingFileRequest } from './uploadFiles';
import { watchVehicleOutgoingPhotosRequest } from './uploadPhotos';
import { watchVehicleOutgoingPhotoRequest } from './uploadPhoto';

export {
  watchVehicleOutgoingReasonRequest,
  watchRegisterOutgoingRequest,
  watchAddFile,
  watchVehicleOutgoingFileRequest,
  watchVehicleOutgoingPhotosRequest,
  watchVehicleOutgoingPhotoRequest
};
