export const TRANSFER_DETAIL_REQUEST = 'TRANSFER_DETAIL_REQUEST/TRANSFER';
export const TRANSFER_DETAIL_SUCCESS = 'TRANSFER_DETAIL_SUCCESS/TRANSFER';
export const TRANSFER_DETAIL_FAIL = 'TRANSFER_DETAIL_FAIL/TRANSFER';

export const HISTORY_DETAIL_REQUEST = 'HISTORY_DETAIL_REQUEST';
export const HISTORY_DETAIL_SUCCESS = 'HISTORY_DETAIL_SUCCESS';
export const HISTORY_DETAIL_CLEAR = 'HISTORY_DETAIL_CLEAR';

export function transferDetailRequest(payload) {
  return {
    type: TRANSFER_DETAIL_REQUEST,
    payload: payload
  };
}

export function transferDetailSuccess(payload) {
  return {
    type: TRANSFER_DETAIL_SUCCESS,
    payload: payload
  };
}

export function transferDetailFail() {
  return {
    type: TRANSFER_DETAIL_FAIL
  };
}

export function historyDetailRequest(payload) {
  return {
    type: HISTORY_DETAIL_REQUEST,
    payload
  };
}

export function historyDetailSuccess(payload) {
  return {
    type: HISTORY_DETAIL_SUCCESS,
    payload
  };
}

export function historyDetailClear() {
  return {
    type: HISTORY_DETAIL_CLEAR
  };
}
