export const IS_FROM_ADVERTS = 'IS_FROM_ADVERTS';
export const CLEAN_IS_FROM_ADVERTS = 'CLEAN_IS_FROM_ADVERTS';

export const setIsFromAdverts = isFromAdverts => ({
  type: IS_FROM_ADVERTS,
  isFromAdverts
});

export const cleanIsFromAdverts = () => ({
  type: CLEAN_IS_FROM_ADVERTS
});
