import { put, call, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import { latestNotifications } from '../../services';
import instance from 'services/request';
import { responseOK } from 'utils';

export function* workNotifications(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const response = yield call(latestNotifications, { payload, cancelToken: source.token });

    if (responseOK(response)) {
      yield put(actions.latestNotificationsSuccess(response.data.data));
    } else {
      yield put(actions.latestNotificationsFail());
    }
  } catch (_error) {
    yield put(actions.latestNotificationsFail());
  }
}

export function* watchLatestNotificationsRequest() {
  yield takeEvery(actions.LATEST_NOTIFICATIONS_REQUEST, workNotifications);
}
