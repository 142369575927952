import { takeEvery, put, all } from 'redux-saga/effects';
import { UPLOAD_OUTGOING_PHOTOS_REQUEST, uploadPhotoRequest, uploadPhotoFail } from '../actions';

export function* workUploadPhotos(action) {
  try {
    const {
      payload: { inventoryId, photos }
    } = action;
    yield all(
      photos?.data?.map(p => {
        let data = {
          name: p.name,
          inventoryId,
          content: p.content,
          asFavorite: p?.favorite
        };
        return put(
          uploadPhotoRequest({
            data
          })
        );
      })
    );
  } catch (_error) {
    yield put(uploadPhotoFail());
  }
}

export function* watchVehicleOutgoingPhotosRequest() {
  yield takeEvery(UPLOAD_OUTGOING_PHOTOS_REQUEST, workUploadPhotos);
}
