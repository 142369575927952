export const VEHICLE_OUTGOING_REASON_REQUEST = 'VEHICLE_OUTGOING_REASON_REQUEST';
export const VEHICLE_OUTGOING_REASON_SUCCESS = 'VEHICLE_OUTGOING_REASON_SUCCESS';
export const VEHICLE_OUTGOING_REASON_FAIL = 'VEHICLE_OUTGOING_REASON_FAIL';

export const REGISTER_OUTGOING_REQUEST = 'REGISTER_OUTGOING_REQUEST';
export const REGISTER_OUTGOING_SUCCESS = 'REGISTER_OUTGOING_SUCCESS';
export const REGISTER_OUTGOING_FAIL = 'REGISTER_OUTGOING_FAIL';

export const ADD_DELIVERY_ORDER_FILE = 'ADD_DELIVERY_ORDER_FILE';
export const REMOVE_DELIVERY_ORDER_FILE = 'REMOVE_DELIVERY_ORDER_FILE';
export const CLEAN_DELIVERY_ORDER_FILE = 'CLEAN_DELIVERY_ORDER_FILE';
export const DELIVERY_ORDER_FILE_REQUEST = 'DELIVERY_ORDER_FILE_REQUEST';

export const UPLOAD_DELIVERY_ORDER_FILE_REQUEST = 'UPLOAD_DELIVERY_ORDER_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export const UPLOAD_OUTGOING_PHOTO_REQUEST = 'UPLOAD_OUTGOING_PHOTO_REQUEST';
export const UPLOAD_OUTGOING_PHOTO_SUCCESS = 'UPLOAD_OUTGOING_PHOTO_SUCCESS';
export const UPLOAD_OUTGOING_PHOTO_FAIL = 'UPLOAD_OUTGOING_PHOTO_FAIL';
export const UPLOAD_OUTGOING_PHOTOS_REQUEST = 'UPLOAD_OUTGOING_PHOTOS_REQUEST';

export const vehicleOutgoingReasonRequest = payload => ({
  type: VEHICLE_OUTGOING_REASON_REQUEST,
  payload
});

export const vehicleOutgoingReasonSuccess = payload => ({
  type: VEHICLE_OUTGOING_REASON_SUCCESS,
  payload
});

export const vehicleOutgoingReasonFail = () => ({
  type: VEHICLE_OUTGOING_REASON_FAIL
});

export function registerOutgoingRequest(payload) {
  return {
    type: REGISTER_OUTGOING_REQUEST,
    payload
  };
}

export function registerOutgoingSuccess(payload) {
  return {
    type: REGISTER_OUTGOING_SUCCESS,
    payload
  };
}

export function registerOutgoingFail(payload) {
  return {
    type: REGISTER_OUTGOING_FAIL,
    payload
  };
}

export function requestDeliveryOrderFile(payload) {
  return {
    type: DELIVERY_ORDER_FILE_REQUEST,
    payload
  };
}
export function addDeliveryOrderFile(payload) {
  return {
    type: ADD_DELIVERY_ORDER_FILE,
    payload
  };
}

export function removeDeliveryOrderFile(payload) {
  return {
    type: REMOVE_DELIVERY_ORDER_FILE,
    payload
  };
}

export function uploadFileRequest(payload) {
  return {
    type: UPLOAD_DELIVERY_ORDER_FILE_REQUEST,
    payload
  };
}

export function uploadFileSuccess(payload) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload
  };
}

export function uploadFileFail(payload) {
  return {
    type: UPLOAD_FILE_FAIL,
    payload
  };
}

export function uploadPhotoRequest(payload) {
  return {
    type: UPLOAD_OUTGOING_PHOTO_REQUEST,
    payload
  };
}

export function uploadPhotoSuccess(payload) {
  return {
    type: UPLOAD_OUTGOING_PHOTO_SUCCESS,
    payload
  };
}

export function uploadPhotoFail(payload) {
  return {
    type: UPLOAD_OUTGOING_PHOTO_FAIL,
    payload
  };
}

export function uploadPhotosRequest(payload) {
  return {
    type: UPLOAD_OUTGOING_PHOTOS_REQUEST,
    payload
  };
}

export function cleanDeliveryOrderFile(payload) {
  return {
    type: CLEAN_DELIVERY_ORDER_FILE,
    payload
  };
}
