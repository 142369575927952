export const VEHICLE_ENTRY_DETAILS_REQUEST = 'VEHICLE_ENTRY_DETAILS_REQUEST';
export const VEHICLE_ENTRY_DETAILS_SUCCESS = 'VEHICLE_ENTRY_DETAILS_SUCCESS';
export const VEHICLE_ENTRY_DETAILS_FAIL = 'VEHICLE_ENTRY_DETAILS_FAIL';
export const VEHICLE_ENTRY_DETAILS_CLEAN = 'VEHICLE_ENTRY_DETAILS_CLEAN';

export const VEHICLE_INVENTORY_FILE_REQUEST = 'VEHICLE_INVENTORY_FILE_REQUEST';
export const VEHICLE_INVENTORY_FILE_SUCCESS = 'VEHICLE_INVENTORY_FILE_SUCCESS';
export const VEHICLE_INVENTORY_FILE_FAIL = 'VEHICLE_INVENTORY_FILE_FAIL';

export const DELIVERY_ORDER_REQUEST = 'DELIVERY_ORDER_REQUEST';
export const DELIVERY_ORDER_SUCCESS = 'DELIVERY_ORDER_SUCCESS';
export const DELIVERY_ORDER_FAIL = 'DELIVERY_ORDER_FAIL';

export const deliveryOrderRequest = payload => ({
  type: DELIVERY_ORDER_REQUEST,
  payload
});

export const deliveryOrderSuccess = () => ({
  type: DELIVERY_ORDER_SUCCESS
});

export const deliveryOrderFail = () => ({
  type: DELIVERY_ORDER_FAIL
});

export const vehicleEntryDetailsRequest = payload => ({
  type: VEHICLE_ENTRY_DETAILS_REQUEST,
  payload
});

export const vehicleEntryDetailsSuccess = payload => ({
  type: VEHICLE_ENTRY_DETAILS_SUCCESS,
  payload
});

export const vehicleEntryDetailsFail = () => ({
  type: VEHICLE_ENTRY_DETAILS_FAIL
});

export const vehicleInventoryFileRequest = payload => ({
  type: VEHICLE_INVENTORY_FILE_REQUEST,
  payload
});

export const vehicleInventoryFileSuccess = payload => ({
  type: VEHICLE_INVENTORY_FILE_SUCCESS,
  payload
});

export const vehicleInventoryFileFail = () => ({
  type: VEHICLE_INVENTORY_FILE_FAIL
});

export function vehiclesEntryDetailsClean() {
  return {
    type: VEHICLE_ENTRY_DETAILS_CLEAN
  };
}
