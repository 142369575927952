import { watchLatestNotificationsRequest } from './LatestNotifications';
import { watchAllNotificationsRequest } from './AllNotifications';
import { watchRemoveNotificationsRequest } from './RemoveNotifications';

import { watchUpdateNotificationsRequest } from './UpdateNotifications';
export {
  watchLatestNotificationsRequest,
  watchAllNotificationsRequest,
  watchUpdateNotificationsRequest,
  watchRemoveNotificationsRequest
};
