export const SEARCH_TRANSFERS_REPORT_REQUEST = 'SEARCH_TRANSFERS_REPORT_REQUEST';
export const SEARCH_TRANSFERS_REPORT_SUCCESS = 'SEARCH_TRANSFERS_REPORT_SUCCESS';
export const SEARCH_TRANSFERS_REPORT_FAIL = 'SEARCH_TRANSFERS_REPORT_FAIL';

export function searchTransfersReportRequest(payload) {
  return {
    type: SEARCH_TRANSFERS_REPORT_REQUEST,
    payload
  };
}

export function searchTransfersReportSuccess(payload) {
  return {
    type: SEARCH_TRANSFERS_REPORT_SUCCESS,
    payload
  };
}

export function searchTransfersReportFail(payload) {
  return {
    type: SEARCH_TRANSFERS_REPORT_FAIL,
    payload
  };
}
