import {
  SEARCH_TRANSFERS_REPORT_REQUEST,
  SEARCH_TRANSFERS_REPORT_SUCCESS,
  SEARCH_TRANSFERS_REPORT_FAIL
} from '../actions/';

// Default state
const searchTransfersReducerDefaultState = {};

export default (state = searchTransfersReducerDefaultState, action) => {
  switch (action.type) {
    case SEARCH_TRANSFERS_REPORT_REQUEST:
      return { ...state, isLoading: true };
    case SEARCH_TRANSFERS_REPORT_SUCCESS:
      return { ...state, isLoading: false, data: action?.payload };
    case SEARCH_TRANSFERS_REPORT_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
