export const UPDATE_OUTGOING_VEHICLE_REQUEST = 'UPDATE_OUTGOING_VEHICLE_REQUEST';
export const UPDATE_OUTGOING_VEHICLE_SUCCESS = 'UPDATE_OUTGOING_VEHICLE_SUCCESS';
export const UPDATE_OUTGOING_VEHICLE_FAIL = 'UPDATE_OUTGOING_VEHICLE_FAIL';

export const UPLOAD_CHECKOUT_PHOTO_REQUEST = 'UPLOAD_CHECKOUT_PHOTO_REQUEST';
export const UPLOAD_CHECKOUT_PHOTO_SUCCESS = 'UPLOAD_CHECKOUT_PHOTO_SUCCESS';
export const UPLOAD_CHECKOUT_PHOTO_FAIL = 'UPLOAD_CHECKOUT_PHOTO_FAIL';

export function updateOutgoingVehicleRequest(payload) {
  return {
    type: UPDATE_OUTGOING_VEHICLE_REQUEST,
    payload
  };
}

export function updateOutgoingVehicleSuccess(payload) {
  return {
    type: UPDATE_OUTGOING_VEHICLE_SUCCESS,
    payload
  };
}

export function updateOutgoingVehicleFail(payload) {
  return {
    type: UPDATE_OUTGOING_VEHICLE_FAIL,
    payload
  };
}

export function uploadOutgoingPhotoRequest(payload) {
  return {
    type: UPLOAD_CHECKOUT_PHOTO_REQUEST,
    payload
  };
}

export function uploadOutgoingPhotoSuccess(payload) {
  return {
    type: UPLOAD_CHECKOUT_PHOTO_SUCCESS,
    payload
  };
}

export function uploadOutgoingPhotoFail(payload) {
  return {
    type: UPLOAD_CHECKOUT_PHOTO_FAIL,
    payload
  };
}
