import { UPDATE_ENTRY_FAIL, UPDATE_ENTRY_REQUEST, UPDATE_ENTRY_SUCCESS } from '../actions/index';

const updateEntry = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ENTRY_REQUEST:
      return { ...state, isPosting: true };
    case UPDATE_ENTRY_SUCCESS:
      return { ...state, isPosting: false };
    case UPDATE_ENTRY_FAIL:
      return { ...state, isPosting: false };
    default:
      return state;
  }
};

export default updateEntry;
