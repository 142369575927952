import { put, call, takeLatest, cancelled, takeEvery, select, all } from 'redux-saga/effects';
import {
  REGISTER_INCOMING_REQUEST,
  registerIncomingFail,
  registerIncomingSuccess,
  UPLOAD_FILE_REQUEST,
  uploadFileSuccess,
  uploadFileFail,
  uploadFileRequest,
  uploadPhotoRequest,
  UPLOAD_PHOTO_REQUEST,
  UPLOAD_PHOTOS_REQUEST,
  uploadPhotoSuccess,
  uploadPhotoFail,
  PHOTOS_ARE_COMPLETED,
  FILE_INSURANCE_REQUEST,
  addFileInsurance
} from '../actions/';
import { registerIncomingInventory, uploadFiles, uploadPhoto } from '../../services';
import { sendNotification } from 'helpers/helperNotifications';
import { getPhotosInventory, getFilesManagement } from './selectors';
import instance from 'services/request';
import { responseOK } from 'utils';
import i18next from 'i18next';

export function* workRegisterIncoming(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data, onSuccess } = payload;
    const photos = yield select(getPhotosInventory);
    const files = yield select(getFilesManagement);
    if (photos?.data?.length >= 6) {
      const response = yield call(registerIncomingInventory, { data, cancelToken: source.token });

      if (responseOK(response)) {
        if (files?.length > 0) {
          let prepareData = {
            inventoryId: response?.data?.data,
            documentTypeId: 38,
            files
          };
          yield put(uploadFileRequest({ data: prepareData }));
        }

        sendNotification('success', response.data.message);
        if (onSuccess) {
          yield onSuccess(response?.data?.data);
        }
        yield put(registerIncomingSuccess(response.data.data));
      } else {
        yield put(registerIncomingFail());
      }
    } else {
      yield put(registerIncomingFail());
      sendNotification('error', i18next.t('MSG_093'));
    }
  } catch (_error) {
    yield put(registerIncomingFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* workUploadFile(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { onSuccess, data } = payload;

    const response = yield call(uploadFiles, { data, cancelToken: source.token });

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess();
      }
      yield put(uploadFileSuccess(response.data.data));
    } else {
      yield put(uploadFileFail());
    }
  } catch (_error) {
    yield put(uploadFileFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* workUploadPhoto(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { onSuccess, data } = payload;
    const photos = yield select(getPhotosInventory);
    if (photos?.data?.length <= 15) {
      const response = yield call(uploadPhoto, { data, cancelToken: source.token });
      if (responseOK(response)) {
        if (onSuccess) {
          yield onSuccess();
        }
        yield put(uploadPhotoSuccess(response.data.data));
      } else {
        yield put(uploadPhotoFail());
      }
    }
  } catch (_error) {
    // console.log('_error :>> ', _error);
    yield put(uploadPhotoFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* workUploadPhotos(action) {
  try {
    const { payload } = action;
    const { inventoryId, onSuccess } = payload;

    const photos = yield select(getPhotosInventory);

    if (photos?.data?.length > 0) {
      yield all(
        photos?.data?.map(p => {
          let data = {
            name: p.name,
            inventoryId,
            content: p.content,
            asFavorite: p?.favorite
          };
          return put(
            uploadPhotoRequest({
              data
            })
          );
        })
      );
      if (onSuccess) {
        yield onSuccess();
      }
    }
  } catch (_error) {
    yield put(uploadPhotoFail());
  }
}

export function* workerAddFileInsurance(action) {
  try {
    const { payload } = action;
    if (payload?.file) {
      const { file } = payload;
      const files = yield select(getFilesManagement);
      let currentFiles = files?.length ?? 0;

      if (currentFiles < 5) {
        yield put(addFileInsurance({ file }));
      } else {
        sendNotification('error', i18next.t('MSG_154'));
      }
    }
    // eslint-disable-next-line no-empty
  } catch (error) { }
}

export function* workerPhotosAreCompleted() {
  const photos = yield select(getPhotosInventory);
  const detail = yield select(state => state?.vehicleIncomingDetails?.data);

  if (detail && photos?.data?.length < 6) {
    yield sendNotification('error', i18next.t('MSG_093'));
  }
}

export function* watchRegisterIncoming() {
  yield takeLatest(REGISTER_INCOMING_REQUEST, workRegisterIncoming);
}

export function* watchUploadFile() {
  yield takeEvery(UPLOAD_FILE_REQUEST, workUploadFile);
}

export function* watchUploadPhoto() {
  yield takeEvery(UPLOAD_PHOTO_REQUEST, workUploadPhoto);
}

export function* watchUploadPhotos() {
  yield takeEvery(UPLOAD_PHOTOS_REQUEST, workUploadPhotos);
}

export function* watchPhotosAreCompleted() {
  yield takeEvery(PHOTOS_ARE_COMPLETED, workerPhotosAreCompleted);
}

export function* watchAddFileInsurance() {
  yield takeEvery(FILE_INSURANCE_REQUEST, workerAddFileInsurance);
}
