import { takeEvery, put, call, cancelled } from 'redux-saga/effects';
import { UPLOAD_OUTGOING_PHOTO_REQUEST, uploadPhotoSuccess, uploadPhotoFail } from '../actions';
import instance from 'services/request';
import { responseOK } from 'utils';
import { uploadPhoto } from '../../services';

export function* workUploadPhoto(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data } = payload;
    const response = yield call(uploadPhoto, { data, cancelToken: source.token });
    if (responseOK(response)) {
      yield put(uploadPhotoSuccess(response.data.data));
    } else {
      yield put(uploadPhotoFail());
    }
  } catch (_error) {
    yield put(uploadPhotoFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchVehicleOutgoingPhotoRequest() {
  yield takeEvery(UPLOAD_OUTGOING_PHOTO_REQUEST, workUploadPhoto);
}
