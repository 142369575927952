export const UPDATE_ONE_CASE_TRANSFER_REQUEST = 'UPDATE_ONE_CASE_TRANSFER_REQUEST';
export const UPDATE_ONE_CASE_TRANSFER_SUCCESS = 'UPDATE_ONE_CASE_TRANSFER_SUCCESS';
export const UPDATE_ONE_CASE_TRANSFER_FAIL = 'UPDATE_ONE_CASE_TRANSFER_FAIL';

export function updateOneCaseTransferRequest(payload) {
  return {
    type: UPDATE_ONE_CASE_TRANSFER_REQUEST,
    payload: payload
  };
}

export function updateOneCaseTransferSuccess(payload) {
  return {
    type: UPDATE_ONE_CASE_TRANSFER_SUCCESS,
    payload: payload
  };
}

export function updateOneCaseTransferFail() {
  return {
    type: UPDATE_ONE_CASE_TRANSFER_FAIL
  };
}
