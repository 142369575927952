import {
  UPDATE_ONE_CASE_TRANSFER_REQUEST,
  UPDATE_ONE_CASE_TRANSFER_SUCCESS,
  UPDATE_ONE_CASE_TRANSFER_FAIL
} from '../actions/index';

export default (state = {}, action) => {
  const { type } = action;

  switch (type) {
    case UPDATE_ONE_CASE_TRANSFER_REQUEST:
      return {
        ...state,
        isPosting: true
      };

    case UPDATE_ONE_CASE_TRANSFER_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_ONE_CASE_TRANSFER_FAIL:
      return {
        ...state,
        isPosting: false
      };

    default:
      return state;
  }
};
