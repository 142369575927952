import {
  TRANSFER_DETAIL_REQUEST,
  TRANSFER_DETAIL_SUCCESS,
  TRANSFER_DETAIL_FAIL,
  HISTORY_DETAIL_REQUEST,
  HISTORY_DETAIL_SUCCESS,
  HISTORY_DETAIL_CLEAR
} from '../actions/index';

function transferDetail(state = {}, action) {
  const { type } = action;

  switch (type) {
    case TRANSFER_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case TRANSFER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action?.payload
      };

    case TRANSFER_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false
      };

    case HISTORY_DETAIL_REQUEST:
      return {
        ...state,
        history: {
          isLoading: true
        }
      };

    case HISTORY_DETAIL_SUCCESS:
      return {
        ...state,
        history: {
          isLoading: false,
          data: action.payload
        }
      };

    case HISTORY_DETAIL_CLEAR:
      return {
        ...state,
        history: {
          isLoading: false
        }
      };

    default:
      return state;
  }
}

export default transferDetail;
