import request from 'services/request';
const serviceInventory = 'Inventories/Api/v1/Inventories/CheckOutUpdate';

export async function updateOutgoingVehicle({ data, cancelToken }) {
  return request(`${serviceInventory}`, {
    method: 'PUT',
    data,
    cancelToken,
    transformResponse: [
      function(d) {
        let current = JSON.parse(d);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}
