import { call, put, takeEvery } from 'redux-saga/effects'; // Add call
import { DELIVERY_ORDER_REQUEST } from '../actions';
import { sendNotification } from 'helpers/helperNotifications';
import { deliveryOrderSuccess, deliveryOrderFail } from '../actions';
import { postDelivery, forceDownloadFile } from 'services/deliverys';
import { responseOK } from 'utils';

export function* workDeliveryOrder(action) {
  const { payload } = action;

  try {
    const { data } = payload;
    const { GenerateAction } = data;
    let response;
    switch (GenerateAction) {
      case 1:
        //sendbyemail
        response = yield call(postDelivery, data);
        break;
      case 2:
        //download order
        response = yield call(forceDownloadFile, data);
        break;
      case 3:
        //sendBuyerbyemail
        response = yield call(postDelivery, data);
        break;
      default:
        break;
    }
    if (responseOK(response)) {
      if (response.data.data.content) {
        sendNotification('success', 'La orden de entrega se genero correctamente');
        yield put(deliveryOrderSuccess());
      } else {
        sendNotification('success', 'La orden de entrega se envio correctamente');
        yield put(deliveryOrderSuccess());
      }
    } else {
      yield put(deliveryOrderFail());
    }
  } catch (error) {
    yield put(deliveryOrderFail());
    //console.log(error);
  }
}

export function* watchDeliveryOrderRequest() {
  yield takeEvery(DELIVERY_ORDER_REQUEST, workDeliveryOrder);
}
