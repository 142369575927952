import { ADD_FILE_INSURANCE, REMOVE_FILE_INSURANCE, CLEAN_FILES } from '../actions/index';

function managmentFiles(state = {}, action) {
  const { type } = action;

  switch (type) {
    case ADD_FILE_INSURANCE:
      return {
        ...state,
        files: [...state.files, action.payload.file]
      };
    case REMOVE_FILE_INSURANCE:
      return {
        ...state,
        files: [...state.files.filter(ph => ph.id !== action.payload?.id)]
      };
    case CLEAN_FILES:
      return {
        ...state,
        files: []
      };
    default:
      return state;
  }
}

export default managmentFiles;
