import { takeEvery, put, call, cancelled, all } from 'redux-saga/effects';
import {
  VEHICLE_INVENTORY_FILE_REQUEST,
  vehicleInventoryFileSuccess,
  vehicleInventoryFileFail
} from '../actions/index';
import instance from 'services/request';
import { responseOK } from 'utils';
import { geInventoryFile, getDownloadInventoryFile } from '../../services';

export function* workInventoryFileRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const data = yield call(geInventoryFile, {
      params: payload,
      cancelToken: source.token
    });
    let response = null;
    if (data?.data?.data.length)
      response = yield all(
        data?.data?.data.map(file =>
          call(getDownloadInventoryFile, {
            params: {
              InventoryId: payload.InventoryId,
              fileId: file.fileId
            },
            cancelToken: source.token
          })
        )
      );

    if (responseOK((response && response[0]) || data)) {
      yield put(
        vehicleInventoryFileSuccess((response && response.map(file => file?.data)) || data.data)
      );
    } else {
      yield put(vehicleInventoryFileFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(vehicleInventoryFileFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchInventoryFileRequest() {
  yield takeEvery(VEHICLE_INVENTORY_FILE_REQUEST, workInventoryFileRequest);
}
