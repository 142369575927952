// Actions
import {
  VEHICLE_OUTGOING_REASON_REQUEST,
  VEHICLE_OUTGOING_REASON_SUCCESS,
  VEHICLE_OUTGOING_REASON_FAIL,
  REGISTER_OUTGOING_REQUEST,
  REGISTER_OUTGOING_SUCCESS,
  REGISTER_OUTGOING_FAIL,
  ADD_DELIVERY_ORDER_FILE,
  REMOVE_DELIVERY_ORDER_FILE,
  CLEAN_DELIVERY_ORDER_FILE
} from '../actions/index';

// Default state
const vehicleOutgoingReasonDefaultState = {
  files: {
    data: []
  }
};

export default (state = vehicleOutgoingReasonDefaultState, action) => {
  switch (action.type) {
    case VEHICLE_OUTGOING_REASON_REQUEST:
      return { ...state, isLoading: true };
    case VEHICLE_OUTGOING_REASON_SUCCESS:
      return { ...state, isLoading: false, data: action?.payload };
    case VEHICLE_OUTGOING_REASON_FAIL:
      return { ...state, isLoading: false };
    case REGISTER_OUTGOING_REQUEST:
      return {
        ...state,
        registerOutgoing: {
          isLoading: true
        }
      };
    case REGISTER_OUTGOING_SUCCESS:
      return {
        ...state,
        registerOutgoing: {
          isLoading: false,
          data: action?.payload
        }
      };
    case REGISTER_OUTGOING_FAIL:
      return {
        ...state,
        registerOutgoing: {
          isLoading: false
        }
      };
    case ADD_DELIVERY_ORDER_FILE:
      return {
        ...state,
        files: { data: [...state?.files?.data, action.payload.file] }
      };

    case REMOVE_DELIVERY_ORDER_FILE:
      return {
        ...state,
        files: {
          data: [...state.files?.data.filter(ph => ph.id !== action.payload.id)]
        }
      };
    case CLEAN_DELIVERY_ORDER_FILE:
      return {
        ...state,
        files: {
          data: []
        }
      };
    default:
      return state;
  }
};
