import { put, call, cancelled, takeEvery } from 'redux-saga/effects';
import {
  UPDATE_OUTGOING_VEHICLE_REQUEST,
  updateOutgoingVehicleFail,
  updateOutgoingVehicleSuccess
} from '../actions';
import { updateOutgoingVehicle } from '../../services';
import instance from 'services/request';
import { responseOK } from 'utils';
import { sendNotification } from 'helpers/helperNotifications';
import i18next from 'i18next';


export function* workUpdateOutgoingVehicle(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const {
      payload: { data, onSuccess }
    } = action;
    const response = yield call(updateOutgoingVehicle, { data, cancelToken: source.token });

    if (responseOK(response)) {
      if (onSuccess) {
        onSuccess();
      }
      sendNotification('success', response.data.message);
      yield put(updateOutgoingVehicleSuccess(response.data.data));
    } else {
      if (response?.data?.message === i18next.t('MSG_168')) {
        sendNotification('info', response.data.message);
      } else {
        sendNotification('error', response.data.message);
      }
      yield put(updateOutgoingVehicleFail());
    }
  } catch (_error) {
    yield put(updateOutgoingVehicleFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchUpdateOutgoingVehicleRequest() {
  yield takeEvery(UPDATE_OUTGOING_VEHICLE_REQUEST, workUpdateOutgoingVehicle);
}
