import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../actions';
import { updateNotifications } from '../../services';
import instance from 'services/request';
import { responseOK } from 'utils';

export function* workNotifications(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const data = {
      notificationIds: [payload]
    };
    const response = yield call(updateNotifications, { data, cancelToken: source.token });

    if (responseOK(response)) {
      yield put(actions.updateNotificationSuccess(response));
      yield put(actions.latestNotificationsRequest());
    } else {
      yield put(actions.updateNotificationFail());
    }
  } catch (_error) {
    yield put(actions.updateNotificationFail());
  }
}

export function* watchUpdateNotificationsRequest() {
  yield takeEvery(actions.UPDATE_NOTIFICATION_REQUEST, workNotifications);
}
