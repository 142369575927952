import { takeEvery, put, call } from 'redux-saga/effects';
import { HISTORY_DETAIL_REQUEST, historyDetailSuccess, historyDetailClear } from '../actions';
import { getTransferActions } from 'modules/Transfer/services';

function* workHistoryDetailRequest(action) {
  try {
    const { payload } = action;
    const response = yield call(getTransferActions, { params: payload.requestData });

    if (typeof response !== 'undefined' && response.status === 200) {
      if (response.data?.destination?.statusLog) {
        yield put(historyDetailSuccess(response.data.destination?.statusLog));
      } else {
        yield put(historyDetailSuccess(response.data.data));
      }
    } else {
      yield put(historyDetailClear('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(historyDetailClear('FAIL'));
  }
}

export function* watchHistoryDetailRequest() {
  yield takeEvery(HISTORY_DETAIL_REQUEST, workHistoryDetailRequest);
}
