import { takeEvery, put, call, cancelled } from 'redux-saga/effects';
import {
  VEHICLE_ENTRY_DETAILS_REQUEST,
  vehicleEntryDetailsSuccess,
  vehicleEntryDetailsFail
} from '../actions/index';
import instance from 'services/request';
import { responseOK } from 'utils';
import { getVehicleDetails } from '../../services';
import { favoritePicUpdate } from 'modules/UpdateVehicleEntries/redux-sagas/actions';

export function* workVehicleEntryDetailsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const response = yield call(getVehicleDetails, {
      params: payload.params,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      yield put(vehicleEntryDetailsSuccess(response.data.data));
      yield put(favoritePicUpdate({ totalFavorites: response.data.data.favoritePics }));
    } else {
      yield put(vehicleEntryDetailsFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(vehicleEntryDetailsFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchVehicleEntryDetailsRequest() {
  yield takeEvery(VEHICLE_ENTRY_DETAILS_REQUEST, workVehicleEntryDetailsRequest);
}
