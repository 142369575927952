// Actions
import { IS_FROM_ADVERTS, CLEAN_IS_FROM_ADVERTS } from '../actions/index';

// Default state
const isFromAdvertsDefaultReducer = {};

export default (state = isFromAdvertsDefaultReducer, action) => {
  switch (action.type) {
    case IS_FROM_ADVERTS:
      return { ...state, isFromAdverts: action?.isFromAdverts };
    case CLEAN_IS_FROM_ADVERTS:
      return { ...state, isFromAdverts: false };
    default:
      return state;
  }
};
