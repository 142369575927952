export const VEHICLES_INCOMING_REQUEST = 'VEHICLES_INCOMING_REQUEST';
export const VEHICLES_INCOMING_SUCCESS = 'VEHICLES_INCOMING_SUCCESS';
export const VEHICLES_INCOMING_FAIL = 'VEHICLES_INCOMING_FAIL';

export const VEHICLES_INCOMING_CLEAN = 'VEHICLES_INCOMING_CLEAN';

export const vehiclesIncomingRequest = payload => ({
  type: VEHICLES_INCOMING_REQUEST,
  payload
});

export const vehiclesIncomingSuccess = payload => ({
  type: VEHICLES_INCOMING_SUCCESS,
  payload
});

export const vehiclesIncomingFail = () => ({
  type: VEHICLES_INCOMING_FAIL
});

export function vehiclesIncomingClean() {
  return {
    type: VEHICLES_INCOMING_CLEAN
  };
}
