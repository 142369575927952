export const CASE_TRANSFER_DETAILS_REQUEST = 'CASE_TRANSFER_DETAILS_REQUEST';
export const CASE_TRANSFER_DETAILS_SUCCESS = 'CASE_TRANSFER_DETAILS_SUCCESS';
export const CASE_TRANSFER_DETAILS_FAIL = 'CASE_TRANSFER_DETAILS_FAIL';

export const CASE_TRANSFER_DETAILS_CLEAN = 'CASE_TRANSFER_DETAILS_CLEAN';

export const caseTransferDetailsRequest = payload => ({
  type: CASE_TRANSFER_DETAILS_REQUEST,
  payload
});

export const caseTransferDetailsSuccess = payload => ({
  type: CASE_TRANSFER_DETAILS_SUCCESS,
  payload
});

export const caseTransferDetailsDetailsFail = () => ({
  type: CASE_TRANSFER_DETAILS_FAIL
});

export const caseTransferDetailsDetailsClean = () => ({
  type: CASE_TRANSFER_DETAILS_CLEAN
});
