import { takeEvery, put, call, cancelled } from 'redux-saga/effects';
import {
  SEARCH_TRANSFERS_REPORT_REQUEST,
  searchTransfersReportSuccess,
  searchTransfersReportFail
} from '../actions';
import instance from 'services/request';
import { getTransferReport } from 'services/transfers';
import { responseOK } from 'utils';

export function* workSearchTranfersRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const response = yield call(getTransferReport, {
      params: payload.params,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      yield put(searchTransfersReportSuccess(response.data.data));
    } else {
      yield put(searchTransfersReportFail('FAIL'));
    }
  } catch (error) {
    yield put(searchTransfersReportFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchSearchTransfersRequest() {
  yield takeEvery(SEARCH_TRANSFERS_REPORT_REQUEST, workSearchTranfersRequest);
}
