import request from 'services/request';
const latest = '/Users/api/v1/users/notifications';
const allNotifications = '/Users/api/v1/users/notifications/Search';

export async function latestNotifications({ data, cancelToken }) {
  return request(`${latest}`, {
    method: 'GET',
    data,
    cancelToken
  });
}
export async function updateNotifications({ data, cancelToken }) {
  return request(`${latest}`, {
    method: 'PUT',
    data,
    cancelToken
  });
}

export async function fetchByPagination({ page, pageLength, cancelToken }) {
  return request(`${allNotifications}?Page=${page}&PageLength=${pageLength}`, {
    method: 'GET',
    cancelToken
  });
}

export async function removeById(id, cancelToken) {
  return request(`${latest}/${id}`, {
    method: 'DELETE',
    cancelToken
  });
}
