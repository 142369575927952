import request from 'services/request';
const serviceURL = 'Transfers/Api/v1/Transfers/CaseTransferDetail/';

export const getCaseTransferDetail = async ({ params, cancelToken }) => {
  const { transferId } = params;
  return request(`${serviceURL}${transferId}`, {
    method: 'GET',
    cancelToken
  });
};
