import { takeEvery, put, cancelled, call } from 'redux-saga/effects'; // Add call
import {
  VEHICLES_INCOMING_REQUEST,
  vehiclesIncomingSuccess,
  vehiclesIncomingFail
} from '../actions';
import { responseOK } from 'utils';
import instance from 'services/request';
import { getVehiclesIncoming } from 'modules/VehiclesInComings/services';

export function* workVehiclesIncomingDetailRequest(action) {
  // Add action between parenthesis
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const {
      payload: { params }
    } = action;
    const response = yield call(getVehiclesIncoming, { params, cancelToken: source.token });
    if (responseOK(response)) {
      yield put(
        vehiclesIncomingSuccess({
          items: response?.data?.data?.items,
          totalPage: response?.data?.data?.totalPage
        })
      );
      // yield put(paymentsSetResults(response.data.data));
    } else {
      yield put(vehiclesIncomingFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(vehiclesIncomingFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
      yield put(vehiclesIncomingFail('FAIL'));
    }
  }
}

export function* watchVehiclesIncomingDetailRequest() {
  yield takeEvery(VEHICLES_INCOMING_REQUEST, workVehiclesIncomingDetailRequest);
}
