import request from 'services/request';
const serviceURL = 'Catalogs/api/v1/catalogs/fixedcatalogs/OutReasons';
const serviceInventory = 'Inventories/Api/v1/Inventories/CheckOut';
const serviceURLFiles = 'Files/Api/v1/Files/Inventory/';
const servicePics = 'Files/Api/v1/Pics/Inventory/CheckIn';

export const getOutgoingReasons = async ({ cancelToken }) => {
  return request(`${serviceURL}`, {
    method: 'GET',
    cancelToken
  });
};

export async function registerOutgoingVehicle({ data, cancelToken }) {
  return request(`${serviceInventory}`, {
    method: 'PUT',
    data,
    cancelToken,
    transformResponse: [
      function(d) {
        let current = JSON.parse(d);
        let customResponse = {
          ...current,
          notInterceptor: true
        };

        return customResponse;
      }
    ]
  });
}

export async function uploadFilesOutgoingVehicle({ data, cancelToken }) {
  return request(`${serviceURLFiles}`, {
    method: 'POST',
    data,
    cancelToken
  });
}

export async function uploadPhoto({ data, cancelToken }) {
  return request(`${servicePics}`, {
    method: 'POST',
    data,
    cancelToken
  });
}
