import * as actions from '../actions/';

const notification = {
  latestNotifications: [],
  allNotifications: {},
  pagination: 1,
  firstLoad: true,
};

export default (state = notification, action) => {
  switch (action.type) {
    case actions.LATEST_NOTIFICATIONS_REQUEST:
      return { ...state, isLoading: true, lastNumber: state?.currentNumber };
    case actions.LATEST_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        firstLoad: false,
        isLoading: false,
        currentNumber: action?.payload.filter(n => n.read === false).length,
        latestNotifications: action?.payload
      };
    case actions.LATEST_NOTIFICATIONS_FAIL:
      return { ...state, latestNotifications: [], isLoading: false };
    case actions.ALL_NOTIFICATIONS_REQUEST:
      return { ...state, isLoading: true };
    case actions.ALL_NOTIFICATIONS_SUCCESS:
      return { ...state, isLoading: false, allNotifications: action?.payload };
    case actions.ALL_NOTIFICATIONS_FAIL:
      return { ...state, allNotifications: {}, isLoading: false };
    case actions.PAGINATION:
      return { ...state, pagination: action?.payload };
    default:
      return state;
  }
};
