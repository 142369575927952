import { takeEvery, put, call, cancelled } from 'redux-saga/effects';
import { UPLOAD_DELIVERY_ORDER_FILE_REQUEST, uploadFileSuccess, uploadFileFail } from '../actions';
import instance from 'services/request';
import { responseOK } from 'utils';
import { uploadFilesOutgoingVehicle } from '../../services';

export function* workVehicleOutgoingFileRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const {
    payload: { data }
  } = action;
  try {
    const response = yield call(uploadFilesOutgoingVehicle, {
      data,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      yield put(uploadFileSuccess(response.data.data));
    } else {
      yield put(uploadFileFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(uploadFileFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchVehicleOutgoingFileRequest() {
  yield takeEvery(UPLOAD_DELIVERY_ORDER_FILE_REQUEST, workVehicleOutgoingFileRequest);
}
