// Actions
import {
  INVENTORY_REQUEST,
  INVENTORY_SUCCESS,
  INVENTORY_FAIL,
  INVENTORY_QUERY,
  INVENTORY_RESET_DATA,
  INVENTORY_RESET_QUERY
} from '../actions/index';

// Default state
const inventoryReducerDefaultState = {};

export default (state = inventoryReducerDefaultState, action) => {
  switch (action.type) {
    case INVENTORY_REQUEST:
      return { ...state, isLoading: true };
    case INVENTORY_SUCCESS:
      return { ...state, isLoading: false, data: action?.payload };
    case INVENTORY_FAIL:
      return { ...state, isLoading: false };
    case INVENTORY_QUERY:
      return { ...state, query: action?.query };
    case INVENTORY_RESET_DATA:
      return { ...state, data: {} };
    case INVENTORY_RESET_QUERY:
      return { ...state, query: undefined };
    default:
      return state;
  }
};
