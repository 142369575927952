export const REGISTER_INCOMING_REQUEST = 'REGISTER_INCOMING_REQUEST';
export const REGISTER_INCOMING_SUCCESS = 'REGISTER_INCOMING_SUCCESS';
export const REGISTER_INCOMING_FAIL = 'REGISTER_INCOMING_FAIL';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export const UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAIL = 'UPLOAD_PHOTO_FAIL';
export const UPLOAD_PHOTOS_REQUEST = 'UPLOAD_PHOTOS_REQUEST';
export const PHOTOS_ARE_COMPLETED = 'PHOTOS_ARE_COMPLETED';

export const FILE_INSURANCE_REQUEST = 'FILE_INSURANCE_REQUEST';
export const ADD_FILE_INSURANCE = 'ADD_FILE_INSURANCE';
export const REMOVE_FILE_INSURANCE = 'REMOVE_FILE_INSURANCE';
export const CLEAN_FILES = 'CLEAN_FILES';

export function requestFileInsurance(payload) {
  return {
    type: FILE_INSURANCE_REQUEST,
    payload
  };
}

export function addFileInsurance(payload) {
  return {
    type: ADD_FILE_INSURANCE,
    payload
  };
}

export function removeFileInsurance(payload) {
  return {
    type: REMOVE_FILE_INSURANCE,
    payload
  };
}

export function cleanFiles() {
  return {
    type: CLEAN_FILES
  };
}

export function photosAreCompleted(payload) {
  return {
    type: PHOTOS_ARE_COMPLETED,
    payload
  };
}

export function registerIncomingRequest(payload) {
  return {
    type: REGISTER_INCOMING_REQUEST,
    payload
  };
}

export function registerIncomingSuccess(payload) {
  return {
    type: REGISTER_INCOMING_SUCCESS,
    payload
  };
}

export function registerIncomingFail(payload) {
  return {
    type: REGISTER_INCOMING_FAIL,
    payload
  };
}

export function uploadFileRequest(payload) {
  return {
    type: UPLOAD_FILE_REQUEST,
    payload
  };
}

export function uploadFileSuccess(payload) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload
  };
}

export function uploadFileFail(payload) {
  return {
    type: UPLOAD_FILE_FAIL,
    payload
  };
}

export function uploadPhotoRequest(payload) {
  return {
    type: UPLOAD_PHOTO_REQUEST,
    payload
  };
}

export function uploadPhotoSuccess(payload) {
  return {
    type: UPLOAD_PHOTO_SUCCESS,
    payload
  };
}

export function uploadPhotoFail(payload) {
  return {
    type: UPLOAD_PHOTO_FAIL,
    payload
  };
}

export function uploadPhotosRequest(payload) {
  return {
    type: UPLOAD_PHOTOS_REQUEST,
    payload
  };
}
