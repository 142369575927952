import * as actions from '../actions';

const updateDefaultState = {};

export default (state = updateDefaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_OUTGOING_VEHICLE_REQUEST:
      return {
        ...state,
        updateOutgoing: {
          isLoading: true
        }
      };
    case actions.UPDATE_OUTGOING_VEHICLE_SUCCESS:
      return {
        ...state,
        updateOutgoing: {
          isLoading: false,
          data: action?.payload
        }
      };
    case actions.UPDATE_OUTGOING_VEHICLE_FAIL:
      return {
        ...state,
        updateOutgoing: {
          isLoading: false
        }
      };
    case actions.UPLOAD_CHECKOUT_PHOTO_REQUEST:
      return {
        ...state,
        uploadOutgoingPhoto: {
          isLoading: true
        }
      };
    case actions.UPLOAD_CHECKOUT_PHOTO_SUCCESS:
      return {
        ...state,
        uploadOutgoingPhoto: {
          isLoading: false,
          data: action?.payload
        }
      };
    case actions.UPLOAD_CHECKOUT_PHOTO_FAIL:
      return {
        ...state,
        uploadOutgoingPhoto: {
          isLoading: false
        }
      };

    default:
      return state;
  }
};
