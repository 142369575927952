import request from 'services/request';
const serviceURL = 'Inventories/Api/v1/Inventories';
const serviceURLFiles = 'Files/Api/v1/Files/Inventory/';
const servicePics = 'Files/Api/v1/Pics/Inventory/CheckIn';
const serviceDeletePic = 'Files/Api/v1/Pics/Inventory/';
const serviceDeleteFile = 'Files/Api/v1/Files/Inventory/';
const serviceUpdateFavorite = 'Files/Api/v1/Pics/FavoriteInventoryPic/Inventory/Favorite/';

export async function updateVehicleEntry({ data, cancelToken }) {
  return request(serviceURL, {
    method: 'PUT',
    data,
    cancelToken
  });
}

export async function updateFiles({ data, cancelToken }) {
  return request(`${serviceURLFiles}`, {
    method: 'POST',
    data,
    cancelToken
  });
}

export async function updatePhoto({ data, cancelToken }) {
  return request(`${servicePics}`, {
    method: 'POST',
    data,
    cancelToken
  });
}

export async function deletePhoto({ inventoryId, fileId, cancelToken }) {
  return request(`${serviceDeletePic}${inventoryId}/${fileId}`, {
    method: 'DELETE',
    cancelToken
  });
}

export async function deleteFile({ payload, cancelToken }) {
  return request(`${serviceDeleteFile}${payload.inventoryId}/${payload.currentFile.fileId}`, {
    method: 'DELETE',
    payload,
    cancelToken
  });
}

export const getInventoryFile = async ({ params, cancelToken }) => {
  return request(`${serviceURLFiles}`, {
    params,
    method: 'GET',
    cancelToken
  });
};

export async function updateFavoritePicture({ payload, cancelToken }) {
  return request(`${serviceUpdateFavorite}${payload.inventoryId}/${payload.fileId}`, {
    payload,
    method: 'PUT',
    cancelToken
  });
}
export async function getDownloadInventoryFile({ params, cancelToken }) {
  const { InventoryId, fileId } = params;
  return request(`${serviceURLFiles}Download/${InventoryId}/${fileId}`, {
    method: 'GET',
    cancelToken
  });
}
