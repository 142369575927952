import {
  watchUpdateEntries,
  watchAddPicture,
  watchUploadFile,
  watchUploadPhoto,
  watchPhotosAreCompleted,
  watchDeletePic,
  watchUpdateRequestFile,
  watchUpdateRemoveFile,
  watchUpdateFavoritePic
} from './UpdateSagas';

export {
  watchUpdateEntries,
  watchAddPicture,
  watchUploadFile,
  watchUploadPhoto,
  watchPhotosAreCompleted,
  watchDeletePic,
  watchUpdateRequestFile,
  watchUpdateRemoveFile,
  watchUpdateFavoritePic
};
