import { takeEvery, put, select } from 'redux-saga/effects';
import { DELIVERY_ORDER_FILE_REQUEST, addDeliveryOrderFile } from '../actions';
import { sendNotification } from 'helpers/helperNotifications';
import { getFilesInventory } from './selectors';

export function* workerAddFile(action) {
  try {
    const { payload } = action;
    const { file } = payload;
    const files = yield select(getFilesInventory);
    let currentFiles = files?.length ?? 0;

    if (currentFiles < 5) {
      yield put(addDeliveryOrderFile({ file }));
    } else {
      sendNotification('error', 'El número máximo de archivos que se pueden cargar son 5.');
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

export function* watchAddFile() {
  yield takeEvery(DELIVERY_ORDER_FILE_REQUEST, workerAddFile);
}
