export const VEHICLE_EXITS_REQUEST = 'VEHICLE_EXITS_REQUEST';
export const VEHICLE_EXITS_SUCCESS = 'VEHICLE_EXITS_SUCCESS';
export const VEHICLE_EXITS_FAIL = 'VEHICLE_EXITS_FAIL';
export const VEHICLE_EXITS_CLEAN = 'VEHICLE_EXITS_CLEAN';

export const vehicleExitsRequest = payload => ({
  type: VEHICLE_EXITS_REQUEST,
  payload
});

export const vehicleExitsSuccess = payload => ({
  type: VEHICLE_EXITS_SUCCESS,
  payload
});

export const vehicleExitsFail = () => ({
  type: VEHICLE_EXITS_FAIL
});

export const vehicleExitsClean = () => ({
  type: VEHICLE_EXITS_CLEAN
});
