import {
  REGISTER_INCOMING_REQUEST,
  REGISTER_INCOMING_SUCCESS,
  REGISTER_INCOMING_FAIL
} from '../actions/index';

function registerIncoming(state = {}, action) {
  const { type } = action;

  switch (type) {
    case REGISTER_INCOMING_REQUEST:
      return {
        ...state,
        isPosting: true
      };
    case REGISTER_INCOMING_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case REGISTER_INCOMING_FAIL:
      return {
        ...state,
        isPosting: false
      };

    default:
      return state;
  }
}

export default registerIncoming;
