export const INVENTORY_REQUEST = 'INVENTORY_REQUEST';
export const INVENTORY_SUCCESS = 'INVENTORY_SUCCESS';
export const INVENTORY_FAIL = 'INVENTORY_FAIL';
export const INVENTORY_QUERY = 'INVENTORY_QUERY';
export const INVENTORY_RESET_DATA = 'INVENTORY_RESET_DATA';
export const INVENTORY_RESET_QUERY = 'INVENTORY_RESET_QUERY';

export const inventoryRequest = payload => ({
  type: INVENTORY_REQUEST,
  payload
});

export const inventorySuccess = payload => ({
  type: INVENTORY_SUCCESS,
  payload
});

export const inventoryFail = payload => ({
  type: INVENTORY_FAIL,
  payload
});

export const inventoryQuery = query => ({
  type: INVENTORY_QUERY,
  query
});

export const inventoryResetData = () => ({
  type: INVENTORY_RESET_DATA
});

export const inventoryResetQuery = () => ({
  type: INVENTORY_RESET_QUERY
});
