export const LATEST_NOTIFICATIONS_REQUEST = 'LATEST_NOTIFICATIONS_REQUEST';
export const LATEST_NOTIFICATIONS_SUCCESS = 'LATEST_NOTIFICATIONS_SUCCESS';
export const LATEST_NOTIFICATIONS_FAIL = 'LATEST_NOTIFICATIONS_FAIL';

export const ALL_NOTIFICATIONS_REQUEST = 'ALL_NOTIFICATIONS_REQUEST';
export const ALL_NOTIFICATIONS_SUCCESS = 'ALL_NOTIFICATIONS_SUCCESS';
export const ALL_NOTIFICATIONS_FAIL = 'ALL_NOTIFICATIONS_FAIL';
export const PAGINATION = 'PAGINATION';

export const REMOVE_NOTIFICATION_SUCCESS = 'REMOVE_NOTIFICATION_SUCCESS';
export const REMOVE_NOTIFICATION_FAIL = 'REMOVE_NOTIFICATION_FAIL';
export const REMOVE_NOTIFICATIONS_REQUEST = 'REMOVE_NOTIFICATIONS_REQUEST';

export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL';

export const latestNotificationsRequest = payload => ({
  type: LATEST_NOTIFICATIONS_REQUEST,
  payload
});

export const latestNotificationsSuccess = payload => ({
  type: LATEST_NOTIFICATIONS_SUCCESS,
  payload
});

export const latestNotificationsFail = () => ({
  type: LATEST_NOTIFICATIONS_FAIL
});

export const allNotificationsRequest = payload => ({
  type: ALL_NOTIFICATIONS_REQUEST,
  payload
});

export const allNotificationsSuccess = payload => ({
  type: ALL_NOTIFICATIONS_SUCCESS,
  payload
});

export const allNotificationsFail = () => ({
  type: ALL_NOTIFICATIONS_FAIL
});

export const pagination = payload => ({
  type: PAGINATION,
  payload
});

export const removeNotificationSuccess = payload => ({
  type: REMOVE_NOTIFICATION_SUCCESS,
  payload
});

export const removeNotificationFail = () => ({
  type: REMOVE_NOTIFICATION_FAIL
});

export const removeNotificationsRequest = payload => ({
  type: REMOVE_NOTIFICATIONS_REQUEST,
  payload
});

export const updateNotificationRequest = payload => ({
  type: UPDATE_NOTIFICATION_REQUEST,
  payload
});

export const updateNotificationSuccess = payload => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload
});

export const updateNotificationFail = () => ({
  type: UPDATE_NOTIFICATION_FAIL
});
