import { takeEvery, put, call, all } from 'redux-saga/effects';
import {
  UPDATE_ONE_CASE_TRANSFER_REQUEST,
  updateOneCaseTransferSuccess,
  updateOneCaseTransferFail
} from '../actions';
import { responseOK } from 'utils';
import { sendNotification } from 'helpers/helperNotifications';
import { locationTypeEnum } from 'models/enums';
import { updateCase, updateTransfers } from '../../services';
import i18next from 'i18next';

export function* workUpdateCaseTransferRequest(action) {
  try {
    const { payload } = action;

    const { requestData, onSuccess } = payload;

    // Checks buyerId
    let buyers;
    if (requestData?.sinisterInfo?.buyerId === 'other') {
      buyers = {
        buyerId: null,
        requestantName: requestData?.sinisterInfo?.applicantName
      };
    } else {
      buyers = {
        buyerId: requestData?.sinisterInfo?.buyerId,
        requestantName: null
      };
    }

    const importedPics = [];
    let location;
    switch (requestData?.ubicationInfo?.locationTypeId) {
      case locationTypeEnum.OTHER:
        location = {
          destinationName: requestData.ubicationInfo.destinationName
        };
        break;
      case locationTypeEnum.CAR_POUND:
        location = {
          branchId: requestData.ubicationInfo.branchId
        };
        break;
      case locationTypeEnum.WORKSHOP:
        location = {
          workshopNumber: requestData.ubicationInfo.workShopCode,
          workshopName: requestData.ubicationInfo.workShopName
        };
        break;
      default:
        location = {};
        break;
    }

    let data = {
      caseId: requestData?.caseId,
      InfoId: requestData?.infoId,
      importedPics,
      vehicleInfo: {
        caseNumber: requestData?.vehicleInfo?.caseNumber,
        report: requestData?.vehicleInfo?.report,
        insurancePolicy: requestData?.vehicleInfo?.insurancePolicy,
        wreckTypeId: requestData?.vehicleInfo?.wreckType,
        wreckSubtypeId: requestData?.vehicleInfo?.wreckSubType,
        brand: requestData?.vehicleInfo?.marketName,
        type: requestData?.vehicleInfo?.type,
        model: requestData?.vehicleInfo?.model,
        color: requestData?.vehicleInfo?.color,
        vehicleTypeId: requestData?.vehicleInfo?.vehicleType,
        policySerial: requestData?.vehicleInfo?.policiyserial,
        vehicleSerial: requestData?.vehicleInfo?.vehicleSerial,
        vehiclePlate: requestData?.vehicleInfo?.vehiclePlate,
        engineNumber: requestData?.vehicleInfo?.engineNumber,
        //wreckSubtypeOther: requestData?.vehicleInfo?.wreckSubTypeOther,
        engineTypeId: requestData?.vehicleInfo?.engineType,
        engineTypeOther: requestData?.vehicleInfo?.engineTypeOther,
        vehicleTypeOther: requestData?.vehicleInfo?.vehicleTypeOther,
        wreckTypeOther: requestData?.vehicleInfo?.wreckSubTypeOther,
        mileage: requestData?.vehicleInfo?.mileage
      },
      wreckVehicleInfo: {
        insuranceCarrierId: requestData?.sinisterInfo?.insuranceId,
        observations: requestData?.sinisterInfo?.observations,
        c1: requestData?.sinisterInfo?.c1,
        c2: requestData?.sinisterInfo?.c2,
        c3: requestData?.sinisterInfo?.c3
      },
      locationInfo: {
        ...location,
        address: {
          id: null,
          roadName: requestData?.ubicationInfo?.roadName,
          outNumber: requestData?.ubicationInfo?.outNumber,
          inNumber: requestData?.ubicationInfo?.inNumber,
          neighborhood: requestData?.ubicationInfo?.neighborhood,
          town: requestData?.ubicationInfo?.town,
          city: requestData?.ubicationInfo?.city,
          stateId: requestData?.ubicationInfo?.stateId,
          countryId: requestData?.ubicationInfo?.countryId,
          zipCode: requestData?.ubicationInfo?.zipCode
        },
        locationTypeId: requestData?.ubicationInfo?.locationTypeId,
        observations: requestData?.ubicationInfo?.observations
      },
      transferCaseInfo: {
        ...buyers
      }
    };

    let destination;
    if (requestData?.destinationInfo?.isOtherSelected?.[0]) {
      destination = {
        otherPlace: requestData?.destinationInfo?.destinationName
      };
    } else {
      destination = {
        carPoundId: requestData?.destinationInfo?.branchId
      };
    }

    let transferData = {
      craneBranchId: requestData?.destinationInfo?.craneBranchId,
      ...destination,
      address: {
        id: null,
        roadName: requestData?.destinationInfo?.roadName,
        outNumber: requestData?.destinationInfo?.outNumber,
        inNumber: requestData?.destinationInfo?.inNumber,
        neighborhood: requestData?.destinationInfo?.neighborhood,
        town: requestData?.destinationInfo?.town,
        city: requestData?.destinationInfo?.city,
        stateId: requestData?.destinationInfo?.stateId,
        countryId: requestData?.destinationInfo?.countryId,
        zipCode: requestData?.destinationInfo?.zipCode
      },
      attenderName: requestData?.destinationInfo?.attender,
      distanceTypeId: requestData?.destinationInfo?.transferTypeId,
      cost: Number(requestData?.destinationInfo?.cost),
      comments: requestData?.destinationInfo?.commentUpdate,
      caseId: requestData?.caseId,
      transferId: requestData?.transferId,
      actionId: requestData?.destinationInfo?.statusTransfer,
      problemId: requestData?.destinationInfo?.typeIncidenceId,
      UpdatedFromCases: false
    };

    const response = yield call(updateCase, { data });
    if (responseOK(response)) {
      const responseTransfer = yield call(updateTransfers, { data: { ...transferData } });
      if (responseOK(responseTransfer)) {
        sendNotification('success', responseTransfer.data.message);
        yield onSuccess();
        yield put(updateOneCaseTransferSuccess(responseTransfer.data.data));
      } else {
        yield put(updateOneCaseTransferFail());
      }
    } else {
      if (response?.data?.message.includes(i18next.t('MSG_162')) || response?.data?.message.includes(i18next.t('MSG_163'))) {
        sendNotification('info', response.data.message);
      } else {
        sendNotification('error', response.data.message);
      }
      yield put(updateOneCaseTransferFail());
    }
  } catch (_error) {
    yield put(updateOneCaseTransferFail());
  }
}

export function* watchUpdateCaseTransferRequest() {
  yield all([takeEvery(UPDATE_ONE_CASE_TRANSFER_REQUEST, workUpdateCaseTransferRequest)]);
}
