import { put, all, takeEvery, select, call } from 'redux-saga/effects';
import * as actions from '../actions';
import { responseOK } from 'utils';
import { getPagination, fetchNotifications } from './selectors';
import { removeById } from '../../services';
import instance from 'services/request';

export function* workNotifications(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const response = yield all(
      payload.map(remove => call(removeById, remove.notificationId, source.token))
    );
    if (responseOK(response && response[0])) {
      yield put(actions.removeNotificationSuccess(response));
      yield put(actions.latestNotificationsRequest());
      yield updatePage(select(fetchNotifications), payload);
    }
  } catch (_error) {
    yield put(actions.removeNotificationFail());
  }
}
export function* updatePage(selector, data) {
  const notifications = yield selector;
  const page = yield select(getPagination);
  if (notifications.length === data.length && page !== 1) {
    yield put(actions.pagination(page - 1));
  } else {
    yield put(
      actions.allNotificationsRequest({
        page,
        pageLength: 10
      })
    );
  }
}

export function* watchRemoveNotificationsRequest() {
  yield takeEvery(actions.REMOVE_NOTIFICATIONS_REQUEST, workNotifications);
}
