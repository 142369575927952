import request from 'services/request';
const serviceURL = '/Inventories/Api/v1/Inventories/CheckDetail/';
const serviceURLFiles = '/Files/Api/v1/Files/Inventory/';

export const getVehicleDetails = async ({ params, cancelToken }) => {
  const { inventoryId } = params;
  return request(`${serviceURL}${inventoryId}`, {
    method: 'GET',
    cancelToken
  });
};

export const geInventoryFile = async ({ params, cancelToken }) => {
  return request(`${serviceURLFiles}`, {
    params,
    method: 'GET',
    cancelToken
  });
};

export const getDownloadInventoryFile = async ({ params, cancelToken }) => {
  const { InventoryId, fileId } = params;
  return request(`${serviceURLFiles}Download/${InventoryId}/${fileId}`, {
    method: 'GET',
    cancelToken
  });
};
