import {
  CASE_TRANSFER_DETAILS_REQUEST,
  CASE_TRANSFER_DETAILS_SUCCESS,
  CASE_TRANSFER_DETAILS_FAIL,
  CASE_TRANSFER_DETAILS_CLEAN
} from '../actions/index';

// Default state
const caseTransferDetailsReducerDefaultState = {};

export default (state = caseTransferDetailsReducerDefaultState, action) => {
  switch (action.type) {
    case CASE_TRANSFER_DETAILS_REQUEST:
      return { ...state, isLoading: true };
    case CASE_TRANSFER_DETAILS_SUCCESS:
      return { ...state, isLoading: false, data: action?.payload };
    case CASE_TRANSFER_DETAILS_FAIL:
      return { ...state, isLoading: false };
    case CASE_TRANSFER_DETAILS_CLEAN:
      return { ...state, data: undefined };
    default:
      return state;
  }
};
