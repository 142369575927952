import request from 'services/request';
const serviceURL = 'Inventories/Api/v1/Inventories/';
const serviceURLFiles = 'Files/Api/v1/Files/Inventory/';
const servicePics = 'Files/Api/v1/Pics/Inventory/CheckIn';

export async function registerIncomingInventory({ data, cancelToken }) {
  return request(`${serviceURL}checkin`, {
    method: 'POST',
    data,
    cancelToken
  });
}

export async function uploadFiles({ data, cancelToken }) {
  return request(`${serviceURLFiles}`, {
    method: 'POST',
    data,
    cancelToken
  });
}

export async function uploadPhoto({ data, cancelToken }) {
  return request(`${servicePics}`, {
    method: 'POST',
    data,
    cancelToken
  });
}
