// Actions
import {
  VEHICLE_ENTRIES_REQUEST,
  VEHICLE_ENTRIES_SUCCESS,
  VEHICLE_ENTRIES_FAIL,
  VEHICLE_ENTRIES_CLEAN
} from '../actions/index';

// Default state
const vehicleEntriesDefaultState = {};

export default (state = vehicleEntriesDefaultState, action) => {
  switch (action.type) {
    case VEHICLE_ENTRIES_REQUEST:
      return { ...state, isLoading: true };
    case VEHICLE_ENTRIES_SUCCESS:
      return { ...state, isLoading: false, data: action?.payload };
    case VEHICLE_ENTRIES_FAIL:
      return { ...state, isLoading: false };
    case VEHICLE_ENTRIES_CLEAN:
      return { ...state, isLoading: false, data: [] };
    default:
      return state;
  }
};
