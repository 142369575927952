import {
  UPDATE_PHOTO_REQUEST,
  UPDATE_PHOTO_SUCCESS,
  UPDATE_PHOTO_FAIL,
  UPDATE_ENABLED_TRANSFER,
  FAVORITE_PIC_UPDATE,
  CLEAN_FAVORITE_PIC
} from '../actions/index';

const updateLoadingEntry = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PHOTO_REQUEST:
      return { ...state, isLoading: true };
    case UPDATE_PHOTO_SUCCESS:
      return { ...state, isLoading: false };
    case UPDATE_PHOTO_FAIL:
      return { ...state, isLoading: false };
    case UPDATE_ENABLED_TRANSFER:
      return { ...state, enabledTransfer: action.payload.data };
    case FAVORITE_PIC_UPDATE:
      return { ...state, favoriteTotal: action.payload.totalFavorites };
    case CLEAN_FAVORITE_PIC:
      return { ...state, favoriteTotal: 0 };
    default:
      return state;
  }
};

export default updateLoadingEntry;
