import { put, call, takeLatest, cancelled, takeEvery, select, all } from 'redux-saga/effects';
import {
  updateRemoveLocalFileInsurance,
  updateEntryFail,
  updateEntrySuccess,
  UPDATE_ENTRY_REQUEST,
  UPDATE_FILE_REQUEST,
  updateFileSuccess,
  updateFileFail,
  updatePhotoRequest,
  UPDATE_PHOTO_REQUEST,
  updatePhotoSuccess,
  updatePhotoFail,
  UPDATE_PHOTOS_ARE_COMPLETED,
  updateAddFileInsurance,
  UPDATE_PHOTO_DELETE,
  UPDATE_ADD_FILES_INSURANCE_DATABASE,
  UPDATE_REMOVE_FILE_INSURANCE,
  updateRemoveDbFile,
  UPDATE_FAVORITE_PIC,
  updateCleanFiles,
  updateAddFail
} from '../actions';
import {
  picsInventoryCheckInRemove,
  PICS_INVENTORY_CHECKIN_ADD,
  picsInventoryIncomingRequest,
  picsInventoryCheckOutRemove
} from 'redux/actions/picsActions';
import {
  deleteFile,
  updateVehicleEntry,
  updateFiles,
  updatePhoto,
  deletePhoto,
  getInventoryFile,
  updateFavoritePicture,
  getDownloadInventoryFile
} from '../../services';
import { getPhotosInventory, getFilesManagement } from './selectors';
import { sendNotification } from 'helpers/helperNotifications';
import instance from 'services/request';
import { responseOK } from 'utils';
import i18next from 'i18next';

export function* workUpdateEntries(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    if (payload) {
      const { data, onSuccess, enabledTransfer } = payload;
      const photos = yield select(getPhotosInventory);
      if (photos?.data?.length >= 6 || enabledTransfer) {
        const response = yield call(updateVehicleEntry, { data, cancelToken: source.token });

        if (responseOK(response)) {
          sendNotification('success', response.data.message);
          if (onSuccess) {
            yield onSuccess(response?.data?.data);
          }
          yield put(updateEntrySuccess(response.data.data));
        } else {
          yield put(updateEntryFail());
        }
      } else {
        yield put(updateEntryFail());
        sendNotification('error', i18next.t('MSG_093'));
      }
    }
  } catch (_error) {
    yield put(updateEntryFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* workerAddPicture(action) {
  try {
    const { payload } = action;
    const { inventoryId } = payload;
    if (inventoryId) {
      let data = {
        name: payload.name,
        inventoryId: payload.inventoryId,
        content: payload.content,
        asFavorite: payload?.favorite
      };
      yield put(
        updatePhotoRequest({
          data
        })
      );
    }
  } catch (error) {
    //console.log(error);
  }
}
export function* workUploadFile(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const files = yield select(getFilesManagement);
  let currentFiles = files?.length ?? 0;
  try {
    const { payload } = action;
    if (payload) {
      const { onSuccess, data } = payload;
      if (currentFiles < 5) {
        const response = yield call(updateFiles, { data, cancelToken: source.token });
        if (responseOK(response)) {
          sendNotification('success', response.data.message);
          if (onSuccess) {
            yield onSuccess(data);
          }
          yield put(updateFileSuccess(response.data.data));
        } else {
          yield put(updateFileFail());
        }
      } else {
        sendNotification('error', 'El número máximo de archivos que se pueden cargar son 5.');
        yield put(updateAddFail());
      }
    }
  } catch (_error) {
    yield put(updateFileFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* workUploadPhoto(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    if (payload) {
      const { onSuccess, data } = payload;
      const photos = yield select(getPhotosInventory);
      if (photos?.data?.length <= 15) {
        const response = yield call(updatePhoto, { data, cancelToken: source.token });
        if (responseOK(response)) {
          if (onSuccess) {
            yield onSuccess();
          }
          sendNotification('success', response.data.message);
          yield put(
            picsInventoryIncomingRequest({
              inventoryId: data.inventoryId,
              type: '39',
              transferId: data?.transferId
            })
          );
          yield put(updatePhotoSuccess(response.data.data));
        } else {
          yield put(updatePhotoFail());
        }
      }
    }
  } catch (_error) {
    yield put(updatePhotoFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* workerDeletePic(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    if (payload?.inventoryId) {
      const { fileId, inventoryId } = payload;
      const response = yield call(deletePhoto, { fileId, inventoryId, cancelToken: source.token });

      if (responseOK(response)) {
        sendNotification('success', response.data.message);
        if (payload.name === 'incoming') {
          yield put(picsInventoryCheckInRemove({ fileId: payload.fileId }));
        } else {
          yield put(picsInventoryCheckOutRemove({ fileId: payload.fileId }));
        }
      }
    }
  } catch (_error) {
    // eslint-disable-next-line no-console
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* workerPhotosAreCompleted() {
  const photos = yield select(getPhotosInventory);
  const detail = yield select(state => state?.vehicleEntryDetails?.data);
  if (detail && photos?.data?.length < 6) {
    yield sendNotification('error', i18next.t('MSG_093'));
  }
}

export function* workerRequesFile(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    if (payload?.data) {
      const { data } = payload;
      const response = yield call(getInventoryFile, { params: data, cancelToken: source.token });
      const files = response.data.data;

      const responseDownload = yield all(
        files.map(file => {
          let prepareData = {
            InventoryId: data.InventoryId,
            fileId: file.fileId
          };
          return call(getDownloadInventoryFile, {
            params: prepareData,
            cancelToken: source.token
          });
        })
      );
      yield put(updateCleanFiles());

      yield all(
        responseDownload.map(download => {
          const file = download.data.data;
          return put(updateAddFileInsurance({ file }));
        })
      );
    }
  } catch (error) {
    // eslint-disable-next-line no-console
  }
}

export function* workerDeleteFile(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    if (payload?.currentFile) {
      const { currentFile } = payload;
      if (currentFile.fileId) {
        const response = yield call(deleteFile, { payload, cancelToken: source.token });
        if (responseOK(response)) {
          sendNotification('success', response.data.message);
          yield put(updateRemoveDbFile({ fileId: currentFile.fileId }));
        }
      } else {
        yield put(updateRemoveLocalFileInsurance({ id: currentFile.id }));
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
  }
}

export function* workerUpdateFavoritePic(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const response = yield call(updateFavoritePicture, { payload, cancelToken: source.token });
    if (responseOK(response)) {
      sendNotification('success', response.data.message);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
  }
}

export function* watchAddPicture() {
  yield takeEvery(PICS_INVENTORY_CHECKIN_ADD, workerAddPicture);
}
export function* watchUpdateFavoritePic() {
  yield takeEvery(UPDATE_FAVORITE_PIC, workerUpdateFavoritePic);
}
export function* watchUpdateEntries() {
  yield takeLatest(UPDATE_ENTRY_REQUEST, workUpdateEntries);
}

export function* watchUploadFile() {
  yield takeEvery(UPDATE_FILE_REQUEST, workUploadFile);
}

export function* watchUploadPhoto() {
  yield takeEvery(UPDATE_PHOTO_REQUEST, workUploadPhoto);
}

export function* watchPhotosAreCompleted() {
  yield takeEvery(UPDATE_PHOTOS_ARE_COMPLETED, workerPhotosAreCompleted);
}

export function* watchDeletePic() {
  yield takeEvery(UPDATE_PHOTO_DELETE, workerDeletePic);
}

export function* watchUpdateRequestFile() {
  yield takeEvery(UPDATE_ADD_FILES_INSURANCE_DATABASE, workerRequesFile);
}

export function* watchUpdateRemoveFile() {
  yield takeEvery(UPDATE_REMOVE_FILE_INSURANCE, workerDeleteFile);
}
