import { put, call, cancelled, takeEvery, select } from 'redux-saga/effects';
import {
  REGISTER_OUTGOING_REQUEST,
  registerOutgoingFail,
  registerOutgoingSuccess,
  uploadFileRequest,
  uploadPhotosRequest
} from '../actions';
import { registerOutgoingVehicle } from '../../services';
import instance from 'services/request';
import { responseOK } from 'utils';
import { getPhotosInventory, getFilesInventory } from './selectors';
import { sendNotification } from 'helpers/helperNotifications';
import i18next from 'i18next';

export function* workRegisterOutgoing(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const {
      payload: { data, onSuccess }
    } = action;
    const photos = yield select(getPhotosInventory);
    const files = yield select(getFilesInventory);
    const response = yield call(registerOutgoingVehicle, { data, cancelToken: source.token });

    if (responseOK(response)) {
      if (files?.length > 0) {
        let prepareData = {
          inventoryId: data?.inventoryId,
          documentTypeId: 41,
          files
        };
        yield put(uploadFileRequest({ data: prepareData }));
      }

      if (photos?.data?.length > 0) {
        yield put(uploadPhotosRequest({ photos, inventoryId: data?.inventoryId }));
      }
      if (onSuccess) {
        onSuccess();
      }
      sendNotification('success', response.data.message);
      yield put(registerOutgoingSuccess(response.data.data));
    } else {
      if (response?.data?.message.includes(i18next.t('MSG_168'))) {
        sendNotification('info', response?.data?.message);
        yield put(registerOutgoingFail());
      } else {
        sendNotification('error', response?.data?.message);
        yield put(registerOutgoingFail());
      }
    }
  } catch (_error) {
    yield put(registerOutgoingFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchRegisterOutgoingRequest() {
  yield takeEvery(REGISTER_OUTGOING_REQUEST, workRegisterOutgoing);
}
